import i18n from 'src/i18n';

import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { actionDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const actionId = item.data?.objectId ?? item.data?.actionId;
  const action = lookupData.actions?.[actionId];
  return {
    message: i18n.t('notifications.messages.actionInsert', {
      title:
        action?.SequentialId && action?.Title
          ? `${action?.Title}`
          : i18n.t('notifications.unknown'),
    }),
    url: action ? actionDetailsUrl(action.Id) : null,
    id: `${getFriendlyId(Parent_Type_Enum.Action, action?.SequentialId)}`,
  };
};
