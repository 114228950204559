import { SideNavigationProps } from '@cloudscape-design/components-themed';
import {
  Activity,
  AlertTriangle,
  Asterisk02,
  BarChart10,
  Bell01,
  Certificate02,
  CheckCircleBroken,
  CheckVerified03,
  FileCheck01,
  Grid01,
  LifeBuoy02,
  List,
  NotificationMessage,
  Settings01,
  Settings04,
  Tool02,
  Zap,
} from '@untitled-ui/icons-react';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/Permission';

import { CountOptions } from '@/components/ConnectedCount';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import {
  actionRegisterUrl,
  assessmentRegisterUrl,
  assessmentResultsRegisterUrl,
  attestationRegisterUrl,
  causesRegisterUrl,
  complianceDashboardUrl,
  complianceMonitoringAssessmentRegisterUrl,
  complianceMonitoringAssessmentResultsRegisterUrl,
  consequencesRegisterUrl,
  dashboardUrl,
  impactRatingsUrl,
  impactsUrl,
  internalAuditDashboardUrl,
  internalAuditRegisterUrl,
  internalAuditReportRegisterUrl,
  internalAuditReportResultsRegisterUrl,
  issueRegisterUrl,
  myItemsUrl,
  policyRegisterUrl,
  publicPoliciesUrl,
  reportAnIssueUrl,
  riskDashboardUrl,
  riskRegisterUrl,
} from '@/utils/urls';

export type NavItem = SideNavigationProps.Item;

type NavItemWithIcon = SideNavigationProps.Item & {
  icon?: JSX.Element;
  count?: CountOptions;
};

export const useNavItems = (): NavItemWithIcon[] => {
  const { t } = useTranslation('common', { keyPrefix: 'navigationMenu' });
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');
  const causesEnabled = !useIsFeatureVisibleToOrg('disable-causes');
  const consequencesEnabled = !useIsFeatureVisibleToOrg('disable-consequences');
  const complianceEnabled = useIsFeatureVisibleToOrg('compliance');
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');
  const policyEnabled = useIsFeatureVisibleToOrg('policy');
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const thirdPartyEnabled = useIsFeatureVisibleToOrg('third_party');
  const attestationsEnabled = useIsFeatureVisibleToOrg('attestations');
  const settingsEnabled = useIsFeatureVisibleToOrg('settings');
  const canViewRisk = useHasPermission('read:risk', undefined, true);
  const canViewDocument = useHasPermission('read:document', undefined, true);
  const canViewControl = useHasPermission('read:control', undefined, true);
  const canViewControlGroup = useHasPermission('read:control_group');
  const canViewAction = useHasPermission('read:action', undefined, true);
  const canViewAppetite = useHasPermission('read:appetite', undefined, true);
  const canViewAttestations = useHasPermission(
    'read:attestation_record',
    undefined,
    true
  );
  const canViewThirdParty = useHasPermission(
    'read:third_party',
    undefined,
    true
  );
  const canViewImpacts = useHasPermission('read:impact');
  const canViewNotifications = useHasPermission('read:notification');
  const canViewAcceptance = useHasPermission(
    'read:acceptance',
    undefined,
    true
  );
  const canViewIssue = useHasPermission('read:issue', undefined, true);
  const canViewIndicator = useHasPermission('read:indicator', undefined, true);
  const canViewAssessments = useHasPermission(
    'read:assessment',
    undefined,
    true
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_entity',
    undefined,
    true
  );
  const canViewComplianceMonitoring = useHasPermission(
    'read:compliance_monitoring_assessment',
    undefined,
    true
  );

  const canViewPublicIssueForm = useHasPermission('read:public_issue_form');
  const canViewPublicPolicies = useHasPermission('read:public_policies');
  const canViewMyItems = useHasPermission('read:my_items');
  const canViewDashboard = useHasPermission('read:dashboard', undefined, true);
  const canViewObligations = useHasPermission(
    'read:obligation',
    undefined,
    true
  );
  const canViewRequests = useHasPermission(
    'read:change_request',
    undefined,
    true
  );
  const canViewReports = useHasPermission('read:report');
  const canViewSettings = useHasPermission('read:settings');
  const reportsEnabled = useIsFeatureVisibleToOrg('reports');

  const navItems: NavItemWithIcon[] = [];

  if (canViewDashboard) {
    navItems.push({
      type: 'link',
      text: t('dashboardTitle'),
      href: dashboardUrl(),
      icon: <Grid01 />,
    });
  }

  if (canViewMyItems) {
    const reportNavItems: NavItemWithIcon = {
      type: 'link',
      text: t('myItemsTitle'),
      href: myItemsUrl(),
      icon: <List />,
    };
    navItems.push(reportNavItems);
  }
  if (navItems.length > 0) {
    navItems.push({ type: 'divider' });
  }

  if (internalAuditEnabled && canViewInternalAudit) {
    const internalAuditChildItem: (NavItemWithIcon & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('internalAudit.dashboardTitle'),
        href: internalAuditDashboardUrl(),
      },
      {
        type: 'link',
        text: t('internalAudit.registerTitle'),
        href: internalAuditRegisterUrl(),
        count: 'internalAudit',
      },
      {
        type: 'link',
        href: internalAuditReportRegisterUrl(),
        text: t('internalAudit.reportsTitle'),
        count: 'internalAuditReport',
      },
      {
        type: 'link',
        href: internalAuditReportResultsRegisterUrl(),
        text: t('internalAudit.findingsTitle'),
        count: 'internalAuditReportResult',
      },
    ];
    const assessmentsNavItem: NavItemWithIcon = {
      type: 'section',
      text: t('internalAudit.sectionTitle'),
      icon: <Certificate02 />,
      items: internalAuditChildItem,
    };
    navItems.push(assessmentsNavItem);
    if (navItems.length > 0) {
      navItems.push({ type: 'divider' });
    }
  }

  if (canViewRisk) {
    const items: (SideNavigationProps.Link & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('risks.dashboardTitle'),
        href: riskDashboardUrl(),
      },
      {
        type: 'link',
        text: t('risks.registerTitle'),
        href: riskRegisterUrl(),
        count: 'risk',
      },
    ];

    if (canViewAppetite && !impactsEnabled) {
      items.push({
        type: 'link',
        text: t('risks.appetitesTitle'),
        href: '/appetites',
        count: 'appetite',
      });
    }

    if (canViewAcceptance) {
      items.push({
        type: 'link',
        text: t('risks.acceptancesTitle'),
        href: '/acceptances',
        count: 'acceptance',
      });
    }

    const riskNavItems: (SideNavigationProps.Section & {
      icon?: JSX.Element;
    })[] = [
      {
        type: 'section',
        text: t('risks.sectionTitle'),
        icon: <Zap />,
        items: items,
      },
    ];

    navItems.push(...riskNavItems);
  }

  if (policyEnabled && canViewDocument) {
    const policyNavItems: (SideNavigationProps.Item & {
      icon?: JSX.Element;
    })[] =
      attestationsEnabled && canViewAttestations
        ? [
            {
              type: 'section',
              text: t('policy.sectionTitle'),
              icon: <FileCheck01 />,
              items: [
                {
                  type: 'link',
                  text: t('policy.registerTitle'),
                  href: policyRegisterUrl(),
                },
                {
                  type: 'link',
                  text: t('policy.attestationsTitle'),
                  href: attestationRegisterUrl(),
                },
              ],
            },
          ]
        : [
            {
              type: 'link',
              text: t('policy.sectionTitle'),
              href: policyRegisterUrl(),
              icon: <FileCheck01 />,
            },
          ];
    navItems.push(...policyNavItems);
  }

  if (canViewObligations && complianceEnabled) {
    const items: (SideNavigationProps.Link & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('compliance.dashboardTitle'),
        href: complianceDashboardUrl(),
      },
      {
        type: 'link',
        text: t('compliance.registerTitle'),
        href: '/compliance',
      },
    ];

    if (complianceMonitoringEnabled && canViewComplianceMonitoring) {
      const complianceMonitoringChildItem: (SideNavigationProps.Link & {
        count?: CountOptions;
      })[] = [
        {
          type: 'link',
          href: complianceMonitoringAssessmentRegisterUrl(),
          text: t('compliance.monitoringTitle'),
          count: 'complianceMonitoringAssessment',
        },
        {
          type: 'link',
          href: complianceMonitoringAssessmentResultsRegisterUrl(),
          text: t('compliance.findingsTitle'),
          count: 'complianceMonitoringAssessmentResult',
        },
      ];
      items.push(...complianceMonitoringChildItem);
    }

    const complianceNavItems: (SideNavigationProps.Section & {
      icon?: JSX.Element;
    })[] = [
      {
        type: 'section',
        text: t('compliance.sectionTitle'),
        icon: <CheckVerified03 />,
        items: items,
      },
    ];
    navItems.push(...complianceNavItems);
  }

  if (canViewThirdParty && thirdPartyEnabled) {
    navItems.push({
      type: 'section',
      text: t('thirdParty.sectionTitle'),
      icon: <Tool02 />,
      items: [
        {
          type: 'link',
          text: t('thirdParty.registerTitle'),
          href: '/third-party',
        },
      ],
    });
  }

  if (navItems.length > 0) {
    navItems.push({ type: 'divider' });
  }

  if (canViewControl) {
    const controlChildrenNavItems: NavItemWithIcon[] = [];
    if (canViewControlGroup) {
      controlChildrenNavItems.push({
        type: 'link',
        text: t('controls.groupsTitle'),
        href: '/control-groups',
        count: 'controlGroup',
      });
    }
    controlChildrenNavItems.push({
      type: 'link',
      text: t('controls.testsTitle'),
      href: '/controls/tests',
      count: 'testResult',
    });

    const childItems: (NavItemWithIcon & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('controls.registerTitle'),
        href: '/controls',
        count: 'control',
      },
      ...controlChildrenNavItems,
    ];

    const controlNavItems: NavItemWithIcon = {
      type: 'section',
      text: t('controls.sectionTitle'),
      icon: <Settings04 />,
      items: childItems,
    };
    navItems.push(controlNavItems);
  }

  if (canViewIssue) {
    const childItems: (NavItemWithIcon & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('issues.registerTitle'),
        href: issueRegisterUrl(),
        count: 'issue',
      },
    ];

    if (causesEnabled) {
      childItems.push({
        type: 'link',
        text: t('issues.causesTitle'),
        href: causesRegisterUrl(),
        count: 'cause',
      });
    }
    if (consequencesEnabled) {
      childItems.push({
        type: 'link',
        text: t('issues.consequencesTitle'),
        href: consequencesRegisterUrl(),
        count: 'consequence',
      });
    }
    if (childItems.length === 1) {
      navItems.push({
        type: 'link',
        href: issueRegisterUrl(),
        text: t('issues.sectionTitle'),
        icon: <AlertTriangle />,
      });
    } else {
      navItems.push({
        type: 'section',
        items: childItems,
        text: t('issues.sectionTitle'),
        icon: <AlertTriangle />,
      });
    }
  }

  if (canViewAction) {
    navItems.push({
      type: 'link',
      text: t('actionsTitle'),
      icon: <CheckCircleBroken />,
      href: actionRegisterUrl(),
    });
  }
  if (canViewIndicator) {
    const indicatorNavItems: NavItemWithIcon = {
      type: 'link',
      text: t('indicatorsTitle'),
      href: `/indicator`,
      icon: <Activity />,
    };
    navItems.push(indicatorNavItems);
  }
  if (canViewAssessments) {
    const assessmentChildItem: (NavItemWithIcon & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        href: assessmentRegisterUrl(),
        text: t('assessments.registerTitle'),
        count: 'assessment',
      },
      {
        type: 'link',
        href: assessmentResultsRegisterUrl(),
        text: t('assessments.findingsTitle'),
        count: 'assessmentResult',
      },
    ];
    const assessmentsNavItem: NavItemWithIcon = {
      type: 'section',
      text: t('assessments.sectionTitle'),
      icon: <Certificate02 />,
      items: assessmentChildItem,
    };
    navItems.push(assessmentsNavItem);
  }

  if (impactsEnabled && canViewImpacts) {
    const childItems: (NavItemWithIcon & {
      count?: CountOptions;
    })[] = [
      {
        type: 'link',
        text: t('impacts.registerTitle'),
        href: impactsUrl(),
        count: 'impact',
      },
      {
        type: 'link',
        text: t('impacts.ratingsTitle'),
        href: impactRatingsUrl(),
        count: 'impactRating',
      },
    ];
    const impactsNavItems: NavItemWithIcon & {
      count?: CountOptions;
    } = {
      type: 'section',
      text: t('impacts.sectionTitle'),
      icon: <Asterisk02 />,
      items: childItems,
    };
    navItems.push(impactsNavItems);
  }

  if (canViewReports && reportsEnabled) {
    const reportNavItems: NavItemWithIcon = {
      type: 'link',
      text: t('reportsTitle'),
      href: `/reports`,
      icon: <BarChart10 />,
    };
    navItems.push(reportNavItems);
  }

  if (navItems.length > 0) {
    navItems.push({ type: 'divider' });
  }

  if (canViewPublicIssueForm) {
    const reportNavItems: NavItemWithIcon = {
      type: 'link',
      text: t('issues.reportAnIssueTitle'),
      icon: <AlertTriangle />,
      href: reportAnIssueUrl(),
    };
    navItems.push(reportNavItems);
  }

  if (policyEnabled && canViewPublicPolicies) {
    const reportNavItems: NavItemWithIcon = {
      type: 'link',
      text: t('publicPoliciesTitle'),
      icon: <FileCheck01 />,
      href: publicPoliciesUrl(),
    };
    navItems.push(reportNavItems);
  }

  if (navItems.length > 0) {
    navItems.push({ type: 'divider' });
  }

  const generalNavItems: NavItemWithIcon[] = [
    {
      type: 'section',
      text: t('support.sectionTitle'),

      icon: <LifeBuoy02 />,
      items: [
        {
          external: true,
          type: 'link',
          text: t('support.customPortalTitle'),
          href: 'https://customerportal.risksmart.com/_hcms/mem/login?redirect_url=https%3A%2F%2Fcustomerportal.risksmart.com%2Ftickets-view',
        },
        {
          type: 'link',
          text: t('support.academyTitle'),
          external: true,
          href: 'https://customerportal.risksmart.com/knowledge',
        },
        {
          type: 'link',
          text: t('support.roadMapTitle'),
          external: true,
          href: 'https://roadmap.risksmart.com',
        },
      ],
    },
  ];

  if (canViewNotifications) {
    generalNavItems.push({
      type: 'link',
      href: '#notifications',
      text: t('notificationsTitle'),
      icon: <Bell01 />,
      count: 'notification',
    });
  }

  navItems.push(...generalNavItems);

  if (approvalsEnabled && canViewRequests) {
    navItems.push({
      type: 'link',
      href: '/requests',
      text: t('requestsTitle'),
      icon: <NotificationMessage />,
      count: 'request',
    });
  }

  if (canViewSettings && settingsEnabled) {
    navItems.push({
      type: 'link',
      href: '/settings',
      text: t('settingsTitle'),
      icon: <Settings01 />,
    });
  }

  return navItems;
};
