import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/FormContext';
import { FormTemplateProps } from '@/components/Form/Form/types';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { WidgetSettingsFormFields } from './WidgetSettingsFormFields';
import { SettingsSchema, settingsSchema } from './widgetSettingsSchema';

export type Props = {
  renderTemplate: (props: FormTemplateProps<SettingsSchema>) => ReactNode;
  settings: SettingsSchema | null;
  onSave: (data: SettingsSchema) => Promise<void>;
  onDismiss: () => void;
};

export const WidgetSettingsForm: FC<Props> = ({
  settings,
  onSave,
  renderTemplate,
  onDismiss,
}) => {
  const { t } = useTranslation('common');
  const values: SettingsSchema = {
    filtering: emptyFilterQuery,
    customTitle: false,
    ...(settings ?? {}),
  };

  return (
    <FormContext
      onSave={onSave}
      defaultValues={values}
      formId={'gigawidget-form'}
      schema={settingsSchema}
      i18n={t('widget')}
      renderTemplate={renderTemplate}
      onDismiss={onDismiss}
    >
      <WidgetSettingsFormFields />
    </FormContext>
  );
};
