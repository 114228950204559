import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';

import {
  calculateDesignEffectiveness,
  calculateOverallEffectiveness,
  calculatePerformanceEffectiveness,
} from './calculateEffectiveness';
import { UNRATED } from './lookupData';
import { ControlFlatFields, ControlTableFields } from './types';

export const useLabelledFields = (records: ControlFlatFields[] | undefined) => {
  const { t } = useTranslation(['common']);

  const frequency = t('frequency');
  const { getLabel } = useRating('effectiveness');
  const getEntityInfo = useEntityInfo();
  return useMemo<ControlTableFields[] | undefined>(() => {
    return records?.map((d) => {
      let parentTitle: string | null = null;
      let parentType: string | null = null;
      const obligationsAndRiskParents = d.parents.filter(
        (p) => p.obligation || p.risk
      );
      const firstParent =
        obligationsAndRiskParents.length > 0
          ? obligationsAndRiskParents[0]
          : null;
      parentType = firstParent?.parent?.ObjectType
        ? getEntityInfo(firstParent?.parent?.ObjectType).singular
        : null;
      if (firstParent?.risk?.Title) {
        parentTitle = firstParent.risk.Title;
      } else if (firstParent?.obligation?.Title) {
        parentTitle = firstParent.obligation.Title;
      }
      const OverallEffectiveness = calculateOverallEffectiveness(d);
      const DesignEffectiveness = calculateDesignEffectiveness(d);
      const PerformanceEffectiveness = calculatePerformanceEffectiveness(d);
      return {
        ...d,
        DesignEffectiveness,
        DesignEffectivenessLabelled:
          DesignEffectiveness === null
            ? UNRATED.label
            : getLabel(DesignEffectiveness) || UNRATED.label,
        PerformanceEffectiveness,
        PerformanceEffectivenessLabelled:
          PerformanceEffectiveness === null
            ? UNRATED.label
            : getLabel(PerformanceEffectiveness) || UNRATED.label,
        OverallEffectiveness,
        OverallEffectivenessLabelled:
          OverallEffectiveness === null
            ? UNRATED.label
            : getLabel(OverallEffectiveness) || UNRATED.label,
        OverallEffectivenessHistory: (d.testResults ?? [])
          .slice(1, 7)
          .map((c) => ({
            rating: c.OverallEffectiveness ?? 0,
            id: c.Id,
            testDate: c.TestDate,
          })),
        ParentTitle: parentTitle,
        ParentType: parentType,
        OwnerName: d.owners,
        OpenIssues: d.open_issue_aggregate.aggregate?.count ?? null,
        IssueCount: d.issues_aggregate.aggregate?.count ?? null,
        LinkedIndicatorCount: d.indicators_aggregate.aggregate?.count ?? 0,
        OpenActions: d.actions_aggregate.aggregate?.count ?? null,
        CreatedByUserName: d.createdByUser?.FriendlyName ?? null,
        TestFrequency: d.TestFrequency ? frequency[d.TestFrequency] : null,
        SequentialIdLabel: d.SequentialId ? `C-${d.SequentialId}` : '',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        LatestRatingDate: d.LatestRatingDate ?? '-',
        NextTestDate: d.NextTestDate ?? '-',
        ControlGroups: d.parents
          .filter((c) => c.group)
          .map((c) => ({
            label: c.group?.Title ?? '',
            id: c.group?.Id ?? '',
          })),
      };
    });
  }, [frequency, getEntityInfo, getLabel, records]);
};
