import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';

import { useGetThirdPartyByIdQuery } from '@/generated/graphql';

const QuestionnairesTab = () => {
  const thirdPartyId = useGetGuidParam('id');
  const { data } = useGetThirdPartyByIdQuery({
    variables: { Id: thirdPartyId },
  });

  return (
    <div>
      <p>Questionnaires for the following third party:</p>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default QuestionnairesTab;
