import { z } from 'zod';

export const Precision = ['day', 'month', 'year'];

const tableSettings = z.object({
  chartType: z.literal('table'),
});

const chartSettings = z.object({
  chartType: z.enum(['bar', 'stacked-bar', 'pie', 'donut']),
  categoryGetter: z.string(),
  subCategoryGetter: z.string().nullish(),
  aggregationType: z.enum(['count', 'sum', 'mean', 'max', 'min']),
  aggregationField: z.string().nullish(),
});

const kpiSettings = z.object({
  chartType: z.literal('kpi'),
  aggregationType: z.enum(['count', 'sum', 'mean', 'max', 'min']),
  aggregationField: z.string().nullish(),
});

const chartTypes = z
  .discriminatedUnion(
    'chartType',
    [tableSettings, chartSettings, kpiSettings],
    {
      errorMap: () => ({ message: 'Required' }),
    }
  )
  .superRefine((data, ctx) => {
    switch (data.chartType) {
      case 'bar':
      case 'pie':
      case 'stacked-bar':
      case 'donut':
      case 'kpi':
        if (data.aggregationType !== 'count' && !data.aggregationField) {
          ctx.addIssue({
            message: 'Required',
            code: z.ZodIssueCode.custom,
            path: ['aggregationField'],
          });
        }
        break;
    }
  });

export const filterSchema = z.object({ filtering: z.any() });
export type FilterSchema = z.infer<typeof filterSchema>;

export const titleSchema = z.object({
  title: z.string().optional(),
  customTitle: z.boolean().optional(),
});
export type TitleSchema = z.infer<typeof titleSchema>;

export const settingsSchema = z
  .object({
    dataSource: z.string(),

    precision: z.enum(Precision as [string, ...string[]]).optional(),

    showFilters: z.boolean().default(true),
    ignoreDashboardDateFilter: z.boolean().default(false),
    customUnit: z.boolean().optional(),
    unit: z.string().default('Total'),
    preferences: z.any().optional(),
  })
  .and(chartTypes)
  .and(filterSchema)
  .and(titleSchema);

export type SettingsSchema = z.infer<typeof settingsSchema>;
