import Linkify from 'linkify-react';
import { FC } from 'react';

type Props = {
  title?: string;
  content: string | { title: string; content: string }[];
};

const HelpSection: FC<Props> = ({ title, content }) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      {typeof content === 'string' ? (
        <Linkify
          as="p"
          options={{
            target: '_blank',
          }}
        >
          {content}
        </Linkify>
      ) : (
        content.map((c, i) => (
          <div key={i}>
            <h4>{c.title}</h4>
            <Linkify
              as="p"
              options={{
                target: '_blank',
              }}
            >
              {c.content}
            </Linkify>
          </div>
        ))
      )}
    </>
  );
};

export default HelpSection;
