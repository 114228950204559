import _ from 'lodash';

import { ImpactRating } from './types';

enum ImpactPerformance {
  Within = 'within',
  Outside = 'outside',
  Unrated = 'unrated',
}

export enum ImpactPerformanceRating {
  Above = 'above',
  Aligned = 'aligned',
  Below = 'below',
}

export enum LikelihoodPerformance {
  Within = 'within',
  Outside = 'outside',
  Unrated = 'unrated',
}

export const getImpactPerformanceRating = (
  item: Pick<ImpactRating, 'Rating'>,
  impactAppetite?: number | null
): ImpactPerformance => {
  if (!_.isNil(item.Rating) && !_.isNil(impactAppetite)) {
    return item.Rating <= impactAppetite
      ? ImpactPerformance.Within
      : ImpactPerformance.Outside;
  }
  return ImpactPerformance.Unrated;
};

export const getLikelihoodPerformanceRating = (
  item: Pick<ImpactRating, 'Likelihood'>,
  likelihoodAppetite?: number | null
): LikelihoodPerformance => {
  if (item.Likelihood && likelihoodAppetite) {
    return item.Likelihood <= likelihoodAppetite
      ? LikelihoodPerformance.Within
      : LikelihoodPerformance.Outside;
  }
  return LikelihoodPerformance.Unrated;
};

export const getPerformanceRatingFromPerformanceScore = (
  performanceScore: number | null
) => {
  if (performanceScore == null) {
    return ImpactPerformance.Unrated;
  }
  return performanceScore >= 0
    ? ImpactPerformance.Within
    : ImpactPerformance.Outside;
};

export const getPerformanceRatingFromRatingAndAppetite = ({
  rating,
  impactAppetite,
}: {
  rating: number;
  impactAppetite: number | null | undefined;
}) => {
  const performanceRating = getImpactPerformanceScore(rating, impactAppetite);
  return getPerformanceRating(performanceRating);
};

export const getPerformanceRating = (
  performanceScore: number | null
): ImpactPerformanceRating | null => {
  if (performanceScore === null) {
    return null;
  }
  if (performanceScore > 0) {
    return ImpactPerformanceRating.Above;
  }
  if (performanceScore < 0) {
    return ImpactPerformanceRating.Below;
  }
  return ImpactPerformanceRating.Aligned;
};

export const getImpactPerformanceScore = (
  rating: number,
  impactAppetite: number | null | undefined
): number | null => {
  if (_.isNil(impactAppetite)) {
    return null;
  }

  return impactAppetite - rating;
};

export const getLikelihoodPerformanceScore = (
  item: {
    Likelihood?: number | null | undefined;
  },
  likelihoodAppetite: number | null | undefined
): number | null => {
  if (_.isNil(likelihoodAppetite) || _.isNil(item.Likelihood)) {
    return null;
  }

  return likelihoodAppetite - item.Likelihood;
};
