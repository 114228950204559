import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import {
  PropertyFilterQuery,
  SortingState,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import {
  queryStringToTableOptions,
  TableOptions,
  tableOptionsToQueryString,
  TypedPropertyFilterQuery,
} from '@risksmart-app/components/Table/tableUtils';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TableFields, TableRecord } from '../types';

/**
 * Store and retrieve filters and sorting in the url after the hash
 *
 * @returns
 */
export const useFiltersFromUrlHash = <T extends TableRecord>(
  fields: TableFields<T>
) => {
  const { hash } = useLocation();

  const navigate = useNavigate();

  const updateUrlHash = useCallback(
    (options: TableOptions<T>) => {
      const queryString = tableOptionsToQueryString<T>({ ...options });

      navigate({ hash: queryString }, { replace: true });
    },
    [navigate]
  );

  const sortingState = useMemo<SortingState<T> | undefined>(() => {
    const tableOptions = queryStringToTableOptions(
      hash.substring(1, hash.length)
    );
    if (tableOptions.sorting) {
      const sortingField = tableOptions.sorting.sortingColumn.sortingField;
      return {
        ...tableOptions.sorting,
        sortingColumn: {
          sortingField,
          sortingComparator: sortingField
            ? fields[sortingField]?.sortingComparator
            : undefined,
        },
      };
    }
    return undefined;
    // ignoring fields as this should never change after first being defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const propertyFilter = useMemo<PropertyFilterQuery | undefined>(() => {
    const tableOptions = queryStringToTableOptions(
      hash.substring(1, hash.length)
    );
    if (tableOptions.filtering) {
      const sanitisedFilteringTokens: readonly PropertyFilterToken[] =
        tableOptions.filtering.tokens.map((token) => {
          try {
            const parsedValue = JSON.parse(token.value);

            return {
              ...token,
              value:
                typeof parsedValue === 'object' ? parsedValue : token.value,
            };
          } catch (e) {
            return token;
          }
        });

      return {
        ...tableOptions.filtering,
        tokens: sanitisedFilteringTokens,
      } as PropertyFilterQuery;
    }
    return undefined;
    // ignoring fields as this should never change after first being defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const setSortingState = (sortingState: SortingState<T>) => {
    updateUrlHash({
      sorting: sortingState,
      filtering: propertyFilter as TypedPropertyFilterQuery<T>,
    });
  };

  const setPropertyFilter = useCallback(
    (propertyFilter: PropertyFilterQuery) => {
      updateUrlHash({
        sorting: sortingState,
        filtering: propertyFilter as TypedPropertyFilterQuery<T>,
      });
    },
    [sortingState, updateUrlHash]
  );

  return {
    sortingState,
    propertyFilter,
    setSortingState,
    setPropertyFilter,
    hash,
  };
};
