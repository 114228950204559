import {
  TableOptions,
  tableOptionsToQueryString,
  TypedPropertyFilterQuery,
} from '@risksmart-app/components/Table/tableUtils';
import { ActionTableFields } from 'src/pages/actions/types';
import { AssessmentRegisterFields } from 'src/pages/assessments/types';
import { CauseRegisterFields } from 'src/pages/causes/types';
import { ObligationTableFields } from 'src/pages/compliance/obligations/types';
import { ConsequenceRegisterFields } from 'src/pages/consequences/types';
import { ControlTableFields } from 'src/pages/controls/types';
import { IssueRegisterFields } from 'src/pages/issues/types';
import { RiskRegisterFields } from 'src/pages/risks/types';

import { Parent_Type_Enum } from '@/generated/graphql';

import { AcceptanceTableFields } from '../pages/acceptances/types';
import { ControlTestTableFields } from '../pages/controls/controlTests/types';
import { IndicatorTableFields } from '../pages/indicators/types';
import { InternalAuditRegisterFields } from '../pages/internal-audit/types';
import { PolicyRegisterFields } from '../pages/policy/types';
import { ChangeRequestRegisterFields } from '../pages/requests/types';
import { ThirdPartyRegisterFields } from '../pages/third-party/types';

export const slackCallbackUrl = () => '/slack-callback';
export const addRiskUrl = () => '/risks/add';
export const riskDetailsUrl = (riskId: string) => `/risks/${riskId}`;

export const riskRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<RiskRegisterFields>
    | undefined = undefined
) =>
  `/risks${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const thirdPartyRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<ThirdPartyRegisterFields>
    | undefined = undefined
) =>
  `/third-party${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const addThirdPartyUrl = () => '/third-party/add';

export const thirdPartyDetailsUrl = (id: string) => `/third-party/${id}`;

export const issueRegisterUrl = (
  options: TableOptions<IssueRegisterFields> | undefined = undefined
) => `/issues${options ? '#' + tableOptionsToQueryString(options) : ''}`;

export const consequencesRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<ConsequenceRegisterFields>
    | undefined = undefined
) =>
  `/consequences${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const causesRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<CauseRegisterFields>
    | undefined = undefined
) =>
  `/causes${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const requestsRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<ChangeRequestRegisterFields>
    | undefined = undefined
) =>
  `/requests${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const acceptanceRegisterUrl = (
  options: TableOptions<AcceptanceTableFields> | undefined = undefined
) => `/acceptances${options ? '#' + tableOptionsToQueryString(options) : ''}`;

export const acceptanceDetailUrl = (acceptanceId: string) =>
  `/acceptances/${acceptanceId}`;

export const issueDetailsUrl = (issueId: string) => `/issues/${issueId}`;
export const issueAssessmentDetailsUrl = (issueId: string) =>
  `/issues/${issueId}/assessment`;

export const controlRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<ControlTableFields>
    | undefined = undefined
) =>
  `/controls${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const controlTestsRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<ControlTestTableFields>
    | undefined = undefined
) =>
  `/controls/tests${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const actionRegisterUrl = (
  filtering: TypedPropertyFilterQuery<ActionTableFields> | undefined = undefined
) =>
  `/actions${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

const assessments = 'assessments';
export const assessmentRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<AssessmentRegisterFields>
    | undefined = undefined
) =>
  `/${assessments}${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const assessmentResultsRegisterUrl = () => `/${assessments}/findings`;
export const assessmentAddUrl = () => `/${assessments}/add`;
export const assessmentDetailsUrl = (assessmentId: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}`;
export const assessmentResultsUrl = (assessmentId?: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}/findings`;
export const assessmentResultsAddUrl = (assessmentId?: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}/findings/add`;
export const assessmentResultsEditUrl = (
  assessmentId?: string,
  assessmentResultId?: string
) =>
  `${assessmentRegisterUrl()}/${assessmentId}/findings/${assessmentResultId}`;

export const assessmentLinkedItemsUrl = (assessmentId: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}/linked-items`;
export const assessmentActivitiesRegisterUrl = (assessmentId: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}/activities`;
export const addAssessmentActivityUrl = (assessmentId: string) =>
  `${assessmentRegisterUrl()}/${assessmentId}/activities/add`;
export const assessmentActivitiesDetailsUrl = (
  assessmentId: string,
  activityId: string
) => `${assessmentRegisterUrl()}/${assessmentId}/activities/${activityId}`;

const internalAudit = 'internal-audits';
export const internalAuditRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<InternalAuditRegisterFields>
    | undefined = undefined
) =>
  `/${internalAudit}${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;
export const internalAuditDashboardUrl = () => `/${internalAudit}/universe`;

export const internalAuditAddUrl = () => `/${internalAudit}/add`;
export const internalAuditDetailsUrl = (internalAuditId: string) =>
  `${internalAuditRegisterUrl()}/${internalAuditId}`;

const internalAuditReport = `${internalAudit}/reports`;
export const internalAuditReportRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<AssessmentRegisterFields>
    | undefined = undefined
) =>
  `/${internalAuditReport}${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const internalAuditReportResultsRegisterUrl = () =>
  `/${internalAuditReport}/findings`;
export const internalAuditReportAddUrl = () => `/${internalAuditReport}/add`;
export const internalAuditReportDetailsUrl = (internalAuditReportId: string) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}`;
export const internalAuditReportResultsUrl = (internalAuditReportId?: string) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/findings`;
export const internalAuditReportResultsAddUrl = (
  internalAuditReportId?: string
) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/findings/add`;
export const internalAuditReportResultsEditUrl = (
  internalAuditReportId?: string,
  internalAuditReportResultId?: string
) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/findings/${internalAuditReportResultId}`;

export const internalAuditReportLinkedItemsUrl = (
  internalAuditReportId: string
) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/linked-items`;
export const internalAuditReportActivitiesRegisterUrl = (
  internalAuditReportId: string
) => `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/activities`;
export const addInternalAuditActivityUrl = (internalAuditReportId: string) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/activities/add`;
export const internalAuditReportActivitiesDetailsUrl = (
  internalAuditReportId: string,
  activityId: string
) =>
  `${internalAuditReportRegisterUrl()}/${internalAuditReportId}/activities/${activityId}`;

const complianceMonitoringAssessment = 'compliance/monitoring-assessments';
export const complianceMonitoringAssessmentRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<AssessmentRegisterFields>
    | undefined = undefined
) =>
  `/${complianceMonitoringAssessment}${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const complianceMonitoringAssessmentResultsRegisterUrl = () =>
  `/${complianceMonitoringAssessment}/findings`;
export const complianceMonitoringAssessmentAddUrl = () =>
  `/${complianceMonitoringAssessment}/add`;
export const complianceMonitoringAssessmentDetailsUrl = (
  complianceMonitoringAssessmentId: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}`;
export const complianceMonitoringAssessmentResultsUrl = (
  complianceMonitoringAssessmentId?: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/findings`;
export const complianceMonitoringAssessmentResultsAddUrl = (
  complianceMonitoringAssessmentId?: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/findings/add`;
export const complianceMonitoringAssessmentResultsEditUrl = (
  complianceMonitoringAssessmentId?: string,
  complianceMonitoringAssessmentResultId?: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/findings/${complianceMonitoringAssessmentResultId}`;

export const complianceMonitoringAssessmentLinkedItemsUrl = (
  complianceMonitoringAssessmentId: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/linked-items`;
export const complianceMonitoringAssessmentActivitiesRegisterUrl = (
  complianceMonitoringAssessmentId: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/activities`;
export const addComplianceMonitoringAssessmentActivityUrl = (
  complianceMonitoringAssessmentId: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/activities/add`;
export const complianceMonitoringAssessmentActivitiesDetailsUrl = (
  complianceMonitoringAssessmentId: string,
  activityId: string
) =>
  `${complianceMonitoringAssessmentRegisterUrl()}/${complianceMonitoringAssessmentId}/activities/${activityId}`;

export const controlDetailsUrl = (
  controlId: string,
  parentId?: string,
  parentType?: Parent_Type_Enum | null
) => {
  switch (parentType) {
    case Parent_Type_Enum.Risk:
      return `${riskDetailsUrl(parentId!)}/controls/${controlId}`;
    case Parent_Type_Enum.Obligation:
      return `${obligationDetailsUrl(parentId!)}/controls/${controlId}`;
    default:
      return `/controls/${controlId}`;
  }
};
export const controlGroupDetailsUrl = (controlGroupId: string) =>
  `/control-groups/${controlGroupId}`;
export const obligationRegister = (
  filtering:
    | TypedPropertyFilterQuery<ObligationTableFields>
    | undefined = undefined
) =>
  `/compliance${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const addObligationUrl = (type?: string) =>
  `/compliance/obligation${type ? `?type=${type}` : ''}`;
export const obligationDetailsUrl = (obligationId: string) =>
  `/compliance/obligation/${obligationId}`;

export const reportAnIssueUrl = () => '/report-an-issue';
export const reportAnIssueSuccessUrl = () => '/issue-reported';
export const logoutUrl = () => '/logout';
export const loginUrl = () => '/login';
export const actionDetailsUrl = (actionId: string) => `/actions/${actionId}`;
export const accessDeniedUrl = () => '/access-denied';

export const riskDashboardUrl = () => '/risks/dashboard';
export const dashboardUrl = () => '/';

export const policyRegisterUrl = (
  filtering:
    | TypedPropertyFilterQuery<PolicyRegisterFields>
    | undefined = undefined
) =>
  `/policy${
    filtering ? '#' + tableOptionsToQueryString({ filtering: filtering }) : ''
  }`;

export const attestationRegisterUrl = () => `/policy/attestations`;

export const addPolicyUrl = () => '/policy/add';
export const policyDetailsUrl = (documentId: string) => `/policy/${documentId}`;
export const policyFilesUrl = (documentId: string) =>
  `/policy/${documentId}/files`;
export const policyFileUrl = (documentId: string, fileId?: string) =>
  `/policy/${documentId}/files/${fileId ?? 'latest'}`;

export const publicPolicyFileUrl = (documentId: string, fileId?: string) =>
  `/public-policies/${documentId}/files/${fileId ?? 'latest'}`;
export const policyFileDetailsUrl = (documentId: string, fileId: string) =>
  `/policy/${documentId}/files/update/${fileId}`;

export const policyFileAttestationDetailsUrl = (
  documentId: string,
  fileId: string
) => `${policyFileDetailsUrl(documentId, fileId)}/attestations`;

export const publicPoliciesUrl = () => '/public-policies';
export const indicatorDetailsUrl = (indicatorId: string) =>
  `/indicator/${indicatorId}`;
export const indicatorRegisterUrl = (
  options: TableOptions<IndicatorTableFields> | undefined = undefined
) => `/indicator${options ? '#' + tableOptionsToQueryString(options) : ''}`;

export const complianceDashboardUrl = () => '/compliance/dashboard';
export const settingsUrl = () => '/settings';
export const settingsGroupsUrl = () => '/settings/groups';

export const settingsEditUserGroupsUrl = (groupId: string) =>
  `/settings/groups/${groupId}/details`;

export const myItemsUrl = () => '/my-items';

export const homeUrl = () => {
  return '/';
};

export const auditItemSearch = (objectId: string) =>
  `/settings/audit#fo=and&k0=Id&o0=%3D&v0=${objectId}`;
export const impactsUrl = () => '/impacts';
export const impactRatingsUrl = () => `${impactsUrl()}/ratings`;

export const impactDetailsUrl = (impactId: string) => `/impacts/${impactId}`;
export const impactDetailRatingUrl = (impactId: string) =>
  `${impactDetailsUrl(impactId)}/rating`;

export const appetiteDetailsUrl = (appetiteId: string) =>
  `/appetites/${appetiteId}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const consequenceDetailsUrl = (issueId: string, consequenceId: string) =>
  `${issueDetailsUrl(issueId)}/consequences`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const causeDetailsUrl = (issueId: string, causeId: string) =>
  `${issueDetailsUrl(issueId)}/causes`;
