import { useTranslation } from 'react-i18next';

import { Version_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { Filter } from '../../components/CustomisableRibbon/Filter';

export const useGetDefaultRibbonFilters = () => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'policy.summary_category_titles',
  });
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const statusLabels = [
    getDocumentFileStatusLabel(Version_Status_Enum.Published),
    getDocumentFileStatusLabel(Version_Status_Enum.PendingApproval),
    getDocumentFileStatusLabel(Version_Status_Enum.Draft),
  ];

  const statusFilters: Filter[] = statusLabels.map((label, index) => ({
    id: crypto.randomUUID(),
    title: label,
    itemFilterQuery: {
      tokens: [
        {
          operator: '=',
          propertyKey: 'Status',
          value: label,
        },
      ],
      operation: 'and',
    },
    itemOrder: index,
  }));

  const defaultRibbonFilters: Filter[] = [
    ...statusFilters,
    {
      id: crypto.randomUUID(),
      title: t('review_due'),
      itemFilterQuery: {
        tokens: [
          {
            operator: '=',
            propertyKey: 'NextReviewDate',
            value: {
              type: 'relative',
              unit: 'day',
              amount: 30,
            },
          },
          {
            operator: '=',
            propertyKey: 'NextReviewDate',
            value: {
              type: 'relative',
              unit: 'year',
              amount: -3,
            },
          },
        ],
        operation: 'or',
      },
      itemOrder: 3,
    },
    {
      id: crypto.randomUUID(),
      title: t('overdue'),
      itemFilterQuery: {
        tokens: [
          {
            operator: '=',
            propertyKey: 'NextReviewDate',
            value: {
              type: 'relative',
              unit: 'year',
              amount: -3,
            },
          },
        ],
        operation: 'and',
      },
      itemOrder: 4,
    },
    {
      id: crypto.randomUUID(),
      title: t('total'),
      itemFilterQuery: emptyFilterQuery,
      itemOrder: 5,
    },
  ];
  return defaultRibbonFilters;
};
