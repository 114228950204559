import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { indicatorDetailsUrl } from '@/utils/urls';

import {
  conformanceRatingFromResults,
  getConformanceTrendRating,
} from './calculateConformanceRating';
import { latestResultValueFromData } from './latestResultValueFromData';
import { IndicatorFlatFields, IndicatorTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<IndicatorTableFields> => {
  const allOwners = useGetOwnersFieldConfig<IndicatorTableFields>();
  const allContributors = useGetContributorsFieldConfig<IndicatorTableFields>();
  const { getByValue: statusGetByValue } = useRating(
    'indicator_conformance_status'
  );
  const { getByValue: trendGetByValue } = useRating(
    'indicator_conformance_trend'
  );
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'indicators.columns',
  });
  const tagField = useGetTagFieldConfig<IndicatorTableFields>();
  const departmentField = useGetDepartmentFieldConfig<IndicatorTableFields>();
  return useMemo(
    () => ({
      SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={indicatorDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      ParentTitle: {
        header: st('parent_title'),
        maxWidth: MAX_COL_WIDTH,
      },
      TestFrequencyLabelled: {
        header: st('test_frequency'),
      },
      ConformanceLabelled: {
        header: st('conformance'),
        cell: (item) => {
          const rating = conformanceRatingFromResults(item);
          return <SimpleRatingBadge rating={statusGetByValue(rating)} />;
        },
      },
      LatestResultLabelled: {
        header: st('latest_result'),
        cell: (item) => {
          const result = latestResultValueFromData(item);
          if (_.isNil(result)) {
            return '-';
          }
          if (item.Unit) {
            return `${result} ${item.Unit}`;
          }
          return result;
        },
      },
      LatestResultDateLabelled: dateColumn(
        st('latest_result_date'),
        'LatestResultDateLabelled'
      ),
      //------------------
      Unit: {
        header: st('unit'),
        cell: (item) => item.Unit || '-',
      },
      CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
      allOwners,
      allContributors,
      ParentType: {
        header: st('parent_type'),
      },
      ModifiedByUser: {
        header: stc('updated_by_id'),
      },
      ModifiedByUserName: {
        header: stc('updated_by_username'),
      },
      Id: {
        header: stc('guid'),
      },
      CreatedByUser: {
        header: stc('created_by_id'),
      },
      CreatedByUserName: {
        header: stc('created_by_username'),
      },
      LowerToleranceNum: {
        header: st('lower_tolerance_num'),
      },
      UpperToleranceNum: {
        header: st('upper_tolerance_num'),
      },
      LowerAppetiteNum: {
        header: st('lower_appetite_num'),
      },
      UpperAppetiteNum: {
        header: st('upper_appetite_num'),
      },
      TargetValueTxt: {
        header: st('target_text_value'),
      },
      tags: tagField,
      departments: departmentField,
      ConformanceTrend: {
        header: st('conformance_trend'),
        cell: (item) => {
          const rating = trendGetByValue(
            getConformanceTrendRating(item, item.latestResults)
          );
          return <SimpleRatingBadge rating={rating} />;
        },
      },
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      st,
      statusGetByValue,
      stc,
      tagField,
      trendGetByValue,
    ]
  );
};

const useGetIndicatorTableProps = (
  records: IndicatorFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<IndicatorTableFields> => {
  const { t: stc } = useTranslation(['common']);

  const fields = useGetFieldConfig();
  const labelledFields = useLabelledFields(records);

  return useMemo(
    () => ({
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: stc('indicators.entity_name'),
      emptyCollectionAction: <></>,
      storageKey: 'IndicatorsRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'ParentTitle',
        'TestFrequencyLabelled',
        'LatestResultLabelled',
        'ConformanceLabelled',
        'ConformanceTrend',
        'LatestResultDateLabelled',
      ],
      fields,
    }),
    [customAttributeSchema, fields, labelledFields, stc]
  );
};

export const useGetCollectionTableProps = (
  records: IndicatorFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<IndicatorTableFields> => {
  const props = useGetIndicatorTableProps(records, customAttributeSchema);

  return useGetTableProps(props);
};

export const useGetIndicatorSmartWidgetTableProps = (
  records: IndicatorFlatFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<IndicatorTableFields>
): TablePropsWithActions<IndicatorTableFields> => {
  const props = useGetIndicatorTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<IndicatorTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
