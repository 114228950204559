import { SelectProps } from '@cloudscape-design/components-themed';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { useHasPermissionLazy } from 'src/rbac/Permission';
import { LinkedItemFields } from 'src/schemas/linkedItemSchema';

import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTypedMultiselect from '@/components/Form/ControlledTypedMultiselect';
import {
  ControlledTypeMultipleSelectProps,
  DataItem,
} from '@/components/Form/ControlledTypedMultiselect/ControlledTypedMultiselect';
import {
  Parent_Type_Enum,
  useGetAcceptancesQuery,
  useGetActionsQuery,
  useGetAppetitesQuery,
  useGetAssessmentsQuery,
  useGetControlGroupsQuery,
  useGetControlsQuery,
  useGetDocumentsQuery,
  useGetImpactListQuery,
  useGetIndicatorsQuery,
  useGetIssuesQuery,
  useGetObligationsQuery,
  useGetRisksQuery,
  useGetThirdPartiesQuery,
} from '@/generated/graphql';
import { useEntityInfo } from '@/hooks/getEntityInfo';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

interface Props {
  excludeIds: string[];
}

const LinkedItemForm: FC<Props> = ({ excludeIds }) => {
  const { control, watch } = useFormContext<LinkedItemFields>();

  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'linkedItems.fields',
  });
  const linkType: Parent_Type_Enum = watch('Type');
  const hasPermission = useHasPermissionLazy();
  const hasAppetiteLinks = useIsFeatureVisibleToOrg('appetite_links');
  const hasThirdParties = useIsFeatureVisibleToOrg('third_party');
  const getEntityInfo = useEntityInfo();
  const { data: acceptanceData, loading: loadingAcceptances } =
    useGetAcceptancesQuery({ skip: linkType !== Parent_Type_Enum.Acceptance });
  const { data: actionData, loading: loadingActions } = useGetActionsQuery({
    skip: linkType !== Parent_Type_Enum.Action,
  });
  const { data: appetiteData, loading: loadingAppetites } =
    useGetAppetitesQuery({
      skip: linkType !== Parent_Type_Enum.Appetite,
    });
  const { data: assessmentData, loading: loadingAssessments } =
    useGetAssessmentsQuery({
      skip: linkType !== Parent_Type_Enum.Assessment,
    });
  const { data: indicatorData, loading: loadingIndicators } =
    useGetIndicatorsQuery({
      skip: linkType !== Parent_Type_Enum.Indicator,
    });
  const { data: impactData, loading: loadingImpacts } = useGetImpactListQuery({
    skip: linkType !== Parent_Type_Enum.Impact,
  });
  const { data: controlData, loading: loadingControls } = useGetControlsQuery({
    skip: linkType !== Parent_Type_Enum.Control,
  });
  const { data: controlGroupData, loading: loadingControlGroups } =
    useGetControlGroupsQuery({
      skip: linkType !== Parent_Type_Enum.ControlGroup,
    });

  const { data: documentData, loading: loadingDocuments } =
    useGetDocumentsQuery({
      skip: linkType !== Parent_Type_Enum.Document,
    });

  const { data: obligationData, loading: loadingObligation } =
    useGetObligationsQuery({
      skip: linkType !== Parent_Type_Enum.Obligation,
    });
  const { data: riskData, loading: loadingRisks } = useGetRisksQuery({
    skip: linkType !== Parent_Type_Enum.Risk,
  });
  const { data: issueData, loading: loadingIssues } = useGetIssuesQuery({
    skip: linkType !== Parent_Type_Enum.Issue,
  });
  const { data: thirdPartyData, loading: loadingThirdParties } =
    useGetThirdPartiesQuery({
      skip: linkType !== Parent_Type_Enum.ThirdParty,
    });

  const typeOptions = useMemo<SelectProps.Options>(() => {
    const types: Parent_Type_Enum[] = [
      Parent_Type_Enum.Acceptance,
      Parent_Type_Enum.Action,
      ...(hasAppetiteLinks ? [Parent_Type_Enum.Appetite] : []),
      Parent_Type_Enum.Control,
      Parent_Type_Enum.ControlGroup,
      Parent_Type_Enum.Document,
      Parent_Type_Enum.Indicator,
      Parent_Type_Enum.Issue,
      Parent_Type_Enum.Obligation,
      Parent_Type_Enum.Risk,
      ...(hasThirdParties ? [Parent_Type_Enum.ThirdParty] : []),
    ];
    return types.map((type) => ({
      value: type,
      label: i18n.format(getEntityInfo(type).singular, 'capitalize'),
    }));
  }, [getEntityInfo, hasAppetiteLinks, hasThirdParties]);

  const targetProps: Omit<
    ControlledTypeMultipleSelectProps<LinkedItemFields, DataItem>,
    'data' | 'loading'
  > = {
    control,
    name: 'Target',
    label: i18n.format(getEntityInfo(linkType).plural, 'capitalize'),
    parentType: linkType,
    renderTokens: true,
    excludedIds: excludeIds,
  };

  return (
    <>
      <ControlledSelect
        control={control}
        name="Type"
        label={st('type')}
        placeholder={st('type_placeholder')}
        description={st('type_help')}
        options={typeOptions}
      />
      {linkType === Parent_Type_Enum.Acceptance && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={acceptanceData?.acceptance ?? []}
          loading={loadingAcceptances}
        />
      )}
      {linkType === Parent_Type_Enum.Action && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={actionData?.action ?? []}
          loading={loadingActions}
        />
      )}
      {linkType === Parent_Type_Enum.ThirdParty && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={thirdPartyData?.third_party ?? []}
          loading={loadingThirdParties}
        />
      )}
      {linkType === Parent_Type_Enum.Appetite && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={appetiteData?.appetite ?? []}
          loading={loadingAppetites}
        />
      )}
      {linkType === Parent_Type_Enum.Assessment && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={assessmentData?.assessment ?? []}
          loading={loadingAssessments}
        />
      )}
      {linkType === Parent_Type_Enum.Indicator && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={indicatorData?.indicator ?? []}
          loading={loadingIndicators}
        />
      )}
      {linkType === Parent_Type_Enum.Impact && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={impactData?.impact ?? []}
          loading={loadingImpacts}
        />
      )}
      {linkType === Parent_Type_Enum.Control && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={controlData?.control ?? []}
          loading={loadingControls}
        />
      )}
      {linkType === Parent_Type_Enum.ControlGroup && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={controlGroupData?.control_group ?? []}
          loading={loadingControlGroups}
        />
      )}
      {linkType === Parent_Type_Enum.Document && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={documentData?.document ?? []}
          loading={loadingDocuments}
        />
      )}
      {linkType === Parent_Type_Enum.Obligation && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={obligationData?.obligation ?? []}
          loading={loadingObligation}
        />
      )}
      {linkType === Parent_Type_Enum.Risk && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={riskData?.risk ?? []}
          loading={loadingRisks}
          filter={(risk) => hasPermission('update:risk', risk)}
        />
      )}
      {linkType === Parent_Type_Enum.Issue && (
        <ControlledTypedMultiselect
          {...targetProps}
          data={issueData?.issue ?? []}
          loading={loadingIssues}
        />
      )}
    </>
  );
};

export default LinkedItemForm;
