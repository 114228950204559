import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAggregation } from 'src/providers/AggregationProvider';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import {
  GetAppetitesGroupedByImpactQuery,
  Parent_Type_Enum,
  Risk_Scoring_Model_Enum,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { RiskScore, useRiskScoreFormatters } from '@/hooks/useRiskScore';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';

import { getAppetitePerformance } from '../appetites/calculateAppetitePerformance';
import { UNRATED } from '../controls/lookupData';
import { getImpactPerformanceScore } from '../impacts/ratings/performanceCalculation';
import { RiskFields, RiskRegisterFields } from './types';

export const useGetLabelledFields = (
  records: RiskFields[] | undefined,
  riskScores: RiskScore[] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined
) => {
  const { t } = useTranslation(['common']);
  const tiers = useMemo(() => t('tiers', { returnObjects: true }), [t]);
  const treatments = useMemo(
    () => t('treatments', { returnObjects: true }),
    [t]
  );
  const statuses = useMemo(() => t('statuses', { returnObjects: true }), [t]);
  const { t: rt } = useTranslation('ratings');
  const controlledRatings = useMemo(
    () => rt('risk_controlled', { returnObjects: true }),
    [rt]
  );
  const riskScoreFormatters = useRiskScoreFormatters();
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getAppetiteLabel } = useRating('risk_appetite');
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const posture = useIsFeatureVisibleToOrg('posture');
  const frequency = useMemo(() => t('frequency'), [t]);
  const { riskModel } = useAggregation();
  return useMemo<RiskRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      const score = riskScores?.find((r) => r.id === d.Id);
      const latestAppetite = d.appetites?.[0]?.appetite;

      const appetitePerformance = getAppetitePerformance({
        UpperAppetite: latestAppetite?.UpperAppetite,
        LowerAppetite: latestAppetite?.LowerAppetite,
        controlledRating: score?.residualRating,
        useRanges: riskModel !== Risk_Scoring_Model_Enum.Default,
        controlledRatings,
        posture,
      });

      const impactPerformanceScore = d.impactRatings.reduce(
        (previous, impactRating) => {
          const impactAppetite = impactAppetites?.find(
            (ia) => ia.Id === impactRating.ImpactId
          );
          const impactAppetiteForRisk = impactAppetite?.appetites.find((a) =>
            a.appetite?.parents.find((p) => p.risk?.Id === d.Id)
          );
          if (_.isNil(impactAppetiteForRisk?.appetite?.ImpactAppetite)) {
            return previous;
          }

          const score = getImpactPerformanceScore(
            impactRating.Rating,
            impactAppetiteForRisk?.appetite?.ImpactAppetite
          );
          return previous + (score || 0);
        },
        0
      );

      return {
        ...d,
        TierLabelled: tiers[String(d.Tier) as keyof typeof tiers],
        TreatmentLabelled:
          treatments[String(d.Treatment) as keyof typeof treatments],
        StatusLabelled: statuses[String(d.Status) as keyof typeof statuses],
        UncontrolledRatingLabelled:
          riskScoreFormatters(score).getInherentLabel() ?? UNRATED.label,
        ControlledRatingLabelled:
          riskScoreFormatters(score).getResidualLabel() ?? UNRATED.label,
        UpperAppetiteLabelled: getAppetiteLabel(
          d.appetites?.[0]?.appetite?.UpperAppetite
        ),
        LowerAppetiteLabelled: getAppetiteLabel(
          d.appetites?.[0]?.appetite?.LowerAppetite
        ),
        AppetitePerformance: appetitePerformance,
        AppetitePerformanceLabelled:
          getAppetitePerformanceByValue(appetitePerformance)?.label ??
          'Undefined',
        LinkedControlCount: d.controls_aggregate.aggregate?.count || 0,
        LinkedIndicatorCount: d.indicators_aggregate.aggregate?.count || 0,
        ParentTitle:
          d.parent?.Title ??
          (d.parentNode
            ? getFriendlyId(Parent_Type_Enum.Risk, d.parentNode?.SequentialId)
            : null) ??
          null,
        UncontrolledRating: score?.inherentRating ?? null,
        ControlledRating: score?.residualRating ?? null,
        UncontrolledScore: score?.inherentScore ?? null,
        ControlledScore: score?.residualScore ?? null,
        UserName: d.createdByUser?.FriendlyName ?? null,
        ControlledLikelihoodValue: score?.residualLikelihood ?? null,
        ControlledImpactValue: score?.residualImpact ?? null,
        UncontrolledImpactValue: score?.inherentImpact ?? null,
        UncontrolledLikelihoodValue: score?.inherentLikelihood ?? null,
        UncontrolledLikelihood: getLikelihoodLabel(
          score?.inherentLikelihood ?? null
        ),
        ControlledLikelihood: getLikelihoodLabel(
          score?.residualLikelihood ?? null
        ),
        ControlledImpact: getImpactLabel(score?.residualImpact ?? null),
        UncontrolledImpact: getImpactLabel(score?.inherentImpact ?? null),
        CustomAttributeData: {
          ...(d.CustomAttributeData || {}),
          // @TODO: support custom attributes for assessment results
        },
        Owner: d.owners,
        OwnerName: d.owners,
        SequentialIdLabel: d.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Risk, d.SequentialId)
          : '',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        ImpactPerformanceScore: impactPerformanceScore,
        LatestRatingDate: d.LatestRatingDate ?? '-',
        NextTestDate: d.NextTestDate ?? '-',
        TestFrequency: d.TestFrequency ? frequency[d.TestFrequency] : null,
        UncontrolledRatingHistory: (d.assessmentResults ?? [])
          .filter(
            (c) =>
              c.riskAssessmentResult &&
              c.riskAssessmentResult.ControlType === 'Uncontrolled'
          )
          .slice(1, 7)
          .map((c) => {
            return {
              rating: c.riskAssessmentResult!.Rating ?? 0,
              id: c.riskAssessmentResult!.Id ?? '-',
              testDate: c.riskAssessmentResult!.TestDate ?? '-',
            };
          }),
        ControlledRatingHistory: (d.assessmentResults ?? [])
          .filter(
            (c) =>
              c.riskAssessmentResult &&
              c.riskAssessmentResult.ControlType === 'Controlled'
          )
          .slice(1, 7)
          .map((c) => {
            return {
              rating: c.riskAssessmentResult!.Rating ?? 0,
              id: c.riskAssessmentResult!.Id ?? '-',
              testDate: c.riskAssessmentResult!.TestDate ?? '-',
            };
          }),
      };
    });
  }, [
    records,
    riskScores,
    riskModel,
    controlledRatings,
    posture,
    tiers,
    treatments,
    statuses,
    riskScoreFormatters,
    getAppetiteLabel,
    getAppetitePerformanceByValue,
    getLikelihoodLabel,
    getImpactLabel,
    frequency,
    impactAppetites,
  ]);
};
