import {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { Settings04 } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import IndicatorsPopover from '@/components/IndicatorsPopover/IndicatorsPopover';
import Link from '@/components/Link';
import Popover from '@/components/Popover';
import ResponsiveRatingBadges from '@/components/ResponsiveRatingBadges';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useGetControlGroupsQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import {
  FieldConfig,
  TableFields,
  TablePropsWithActions,
} from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { controlDetailsUrl } from '@/utils/urls';

import { ControlFlatFields, ControlTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  onEditRating?:
    | ((item: { controlId: string; ratingId: string }) => void)
    | null
): TableFields<ControlTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ControlTableFields>();
  const allContributors = useGetContributorsFieldConfig<ControlTableFields>();
  const tagField = useGetTagFieldConfig<ControlTableFields>();
  const controlGroups = useGetControlGroupFieldConfig<ControlTableFields>();
  const { getByValue, options } = useRating('effectiveness');
  let maxRating = Number(
    options.sort(
      (a, b) =>
        (!isNaN(Number(b.value)) ? Number(b.value) : 0) -
        (!isNaN(Number(a.value)) ? Number(a.value) : 0)
    )[0].value
  );
  if (isNaN(maxRating)) {
    maxRating = 0;
  }
  const departmentField = useGetDepartmentFieldConfig<ControlTableFields>();
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  return {
    SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
    Title: {
      header: st('title'),
      cell: (item) => (
        <Link variant="secondary" href={controlDetailsUrl(item.Id)}>
          {item.Title}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    Type: {
      header: st('type'),
      cell: (item) => item.Type || 'None',
    },
    ParentTitle: {
      header: st('parent_title'),
      cell: (item) => {
        if (!item.ParentTitle) {
          return '-';
        }
        return `${item.ParentTitle} (${item.ParentType})`;
      },
      maxWidth: MAX_COL_WIDTH,
      exportVal: (item) => {
        if (!item.ParentTitle) {
          return '';
        }
        return `${item.ParentTitle} (${item.ParentType})`;
      },
    },
    allOwners,
    allContributors,
    DesignEffectivenessLabelled: {
      header: st('design_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.DesignEffectiveness)}>
            {item.DesignEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) => getByValue(item.DesignEffectiveness)?.label ?? '',
    },
    PerformanceEffectivenessLabelled: {
      header: st('performance_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.PerformanceEffectiveness)}>
            {item.PerformanceEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) =>
        getByValue(item.PerformanceEffectiveness)?.label ?? '',
    },
    OverallEffectivenessLabelled: {
      header: st('effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.OverallEffectiveness)}>
            {item.OverallEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) => getByValue(item.OverallEffectiveness)?.label ?? '',
    },
    OverallEffectivenessHistory: {
      header: 'Overall Effectiveness History',
      filterOptions: {
        filteringProperties: {
          operators: [],
        },
      },
      cell: (item) => {
        return (
          <ResponsiveRatingBadges
            maxRating={maxRating}
            invertRating={false}
            ratings={item.OverallEffectivenessHistory?.filter(
              (c) => c?.rating !== undefined
            ).map((c) => ({
              label: '-',
              ...getByValue(c.rating!),
              id: c.id,
              rating: c.rating,
              testDate: c.testDate,
              onClick: onEditRating
                ? () => onEditRating({ controlId: item.Id, ratingId: c.id })
                : undefined,
            }))}
          ></ResponsiveRatingBadges>
        );
      },
      minWidth: 160,
      exportVal: (item) =>
        item.OverallEffectivenessHistory?.map(
          (c) => `${toLocalDate(c.testDate)} ${getByValue(c.rating)?.label}`
        ).join(',') ?? '',
    },
    OpenIssues: {
      header: st('open_issues'),
      cell: (item) => item.OpenIssues ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    LinkedIndicatorCount: {
      header: st('linked_indicators'),
      cell: (item) => (
        <IndicatorsPopover id={item.Id} count={item.LinkedIndicatorCount} />
      ),
    },
    IssueCount: {
      header: st('issues'),
      cell: (item) => item.IssueCount ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },

    OpenActions: {
      header: st('open_actions'),
      cell: (item) => item.OpenActions ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    tags: tagField,
    //------------------
    departments: departmentField,
    CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
    Description: {
      header: st('description'),
      cell: (item) => item.Description || '-',
      maxWidth: MAX_COL_WIDTH,
    },
    Id: {
      header: stc('guid'),
    },
    TestFrequency: {
      header: st('test_frequency'),
      cell: (item) => {
        return item.TestFrequency ?? '-';
      },
    },
    ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: stc('created_by_id'),
    },
    CreatedByUserName: {
      header: stc('created_by_username'),
    },
    ParentType: {
      header: st('parent_type'),
    },
    LatestRatingDate: dateColumn(st('latest_rating_date'), 'LatestRatingDate'),
    NextTestDate: dateColumn(st('next_test_date'), 'NextTestDate'),
    ControlGroups: controlGroups,
  };
};

export function useGetControlGroupFieldConfig<
  T extends {
    ControlGroups: { label: string; id: string }[];
  },
>(): FieldConfig<T> {
  const { data: controlGroups } = useGetControlGroupsQuery();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  const controlGroupsOptions = useMemo(
    () =>
      controlGroups?.control_group.map((u) => ({
        label: u.Title ?? '',
        id: u.Id ?? '',
      })) ?? [],
    [controlGroups?.control_group]
  );
  return useMemo(
    () => ({
      header: st('control_groups'),
      cell: (item) => (
        <Popover
          title={st('control_groups')}
          icon={<Settings04 color="#A2A2B3" />}
          items={item.ControlGroups.map((c) => ({
            id: c?.id ?? '-',
            label: c?.label ?? '-',
            onClick: undefined,
          }))}
          iconName={undefined}
        />
      ),
      filterOptions: {
        filteringProperties:
          createControlGroupFieldPropertyFilter(controlGroupsOptions),
      },
      exportVal: (item) =>
        item.ControlGroups.map((group) => group?.label || '').join(','),
    }),
    [st, controlGroupsOptions]
  );
}

const createControlGroupFieldPropertyFilter = (
  options: { label: string; id: string }[]
): Partial<PropertyFilterProperty> => {
  return {
    operators: [
      ...(['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
        operator,
        format: (id: string): string => {
          return options.find((t) => t.id === id)?.label || '-';
        },
        match: (groups: unknown, id: string) => {
          return !!((groups || []) as { label: string; id: string }[]).find(
            (t) => t.id === id
          );
        },
      })),
    ],
  };
};

const useGetControlTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null,
  onEditRating?:
    | ((item: { controlId: string; ratingId: string }) => void)
    | null
): UseGetTablePropsOptions<ControlTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const fields = useGetFieldConfig(onEditRating);

  const labelledFields = useLabelledFields(records);

  return {
    data: labelledFields,
    customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'ControlRegisterTable-PreferencesV2',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'Type',
      'ParentTitle',
      'allOwners',
      'OverallEffectivenessLabelled',
      'OpenIssues',
      'OpenActions',
      'tags',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  onEditRating: (item: { controlId: string; ratingId: string }) => void,
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(
    records,
    customAttributeSchema,
    onEditRating
  );
  return useGetTableProps(props);
};

export const useGetControlSmartWidgetTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ControlTableFields>
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<ControlTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
