import i18n from 'src/i18n';

import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { controlDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const controlId = item.data?.objectId;
  const control = lookupData.controls?.[controlId];
  return {
    message: i18n.t('notifications.messages.controlUpdate', {
      title:
        control?.SequentialId && control?.Title
          ? `${control?.Title}`
          : i18n.t('notifications.unknown'),
    }),
    url: control ? controlDetailsUrl(control.Id) : null,
    id: `${getFriendlyId(Parent_Type_Enum.Control, control?.SequentialId)}`,
  };
};
