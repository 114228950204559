import {
  Badge,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components-themed';
import { FC } from 'react';

import { useGetIndicatorTitlesByParentIdQuery } from '@/generated/graphql';

type Props = {
  parentId: string;
};

const IndicatorPopoverContent: FC<Props> = ({ parentId }) => {
  const { data, loading } = useGetIndicatorTitlesByParentIdQuery({
    variables: { parentId },
  });
  return (
    <SpaceBetween direction="horizontal" size="xs">
      {loading && <Spinner size="normal" />}
      {data?.indicator?.map((indicator) => (
        <Badge key={indicator.Id}>{indicator.Title}</Badge>
      ))}
    </SpaceBetween>
  );
};

export default IndicatorPopoverContent;
