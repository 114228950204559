import { useTranslation } from 'react-i18next';

import {
  Parent_Type_Enum,
  useGetThirdPartyByIdLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import useActionExportTable from '../../actions/update/useActionExportTable';
import useControlsExportTable from '../../controls/update/useControlsExportTable';
import useIssuesExportTable from '../../issues/update/useIssuesExportTable';

export const useExporter = (thirdPartyId: string) => {
  const [getThirdParty, getThirdPartyResult] = useGetThirdPartyByIdLazyQuery({
    variables: {
      Id: thirdPartyId,
    },
  });
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.ThirdParty);
  const [createControlsTable, controlsLoading] =
    useControlsExportTable(thirdPartyId);
  const [createActionTable, actionsLoading] =
    useActionExportTable(thirdPartyId);
  const [createIssuesTable, issuesLoading] = useIssuesExportTable(thirdPartyId);

  const { t } = useTranslation(['common']);
  const { t: fields } = useTranslation(['common'], {
    keyPrefix: 'third_party.fields',
  });
  const statusRating = useRating('third_party_status');
  const typeRating = useRating('third_party_type');
  const criticalityRating = useRating('third_party_criticality');

  const loading =
    getThirdPartyResult.loading ||
    customAttributesLoading ||
    controlsLoading ||
    actionsLoading ||
    issuesLoading;

  const exportFunc = async () => {
    const { data } = await getThirdParty();

    const thirdParty = data?.third_party;
    if (!thirdParty) return;

    const controlsTable = await createControlsTable();
    const actionsTable = await createActionTable();
    const issuesTable = await createIssuesTable();

    const title = thirdParty?.Title || '';

    const detailFields = [
      createField(fields('description'), thirdParty.Description || '-'),
      createField(fields('companyName'), thirdParty.CompanyName || '-'),
      createField(
        fields('companiesHouseNumber'),
        thirdParty.CompaniesHouseNumber || '-'
      ),
      createField(fields('address'), thirdParty.Address || '-'),
      createField(fields('cityTown'), thirdParty.CityTown || '-'),
      createField(fields('postcode'), thirdParty.Postcode || '-'),
      createField(fields('country'), thirdParty.Country || '-'),
      createField(
        fields('primaryContactName'),
        thirdParty.PrimaryContactName || '-'
      ),
      createField(fields('contactName'), thirdParty.ContactName || '-'),
      createField(fields('contactEmail'), thirdParty.ContactEmail || '-'),
      createField(fields('companyDomain'), thirdParty.CompanyDomain || '-'),
      createField(fields('type'), typeRating.getLabel(thirdParty.Type) || '-'),
      createField(
        fields('status'),
        statusRating.getLabel(thirdParty.Status) || '-'
      ),
      createField(
        fields('criticality'),
        criticalityRating.getLabel(thirdParty.Criticality) || '-'
      ),
      createField(fields('owners'), getOwnerValue(thirdParty)),
      createField(fields('contributors'), getContributorValue(thirdParty)),
      createField(t('fields.Tags'), getTagsValue(thirdParty)),
      createField(t('fields.Departments'), getDepartmentsValue(thirdParty)),
      ...(await getCustomAttribute(thirdParty)),
    ];

    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      optionalTableSection(t('controls.tab_title'), controlsTable),
      optionalTableSection(t('issues.tab_title'), issuesTable),
      optionalTableSection(t('actions.tab_title'), actionsTable),
    ]);

    download(docDefinition);
  };

  return [exportFunc, { loading }] as const;
};
