import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { yesNoCell } from '@/utils/table/utils/yesNoCell';

import { ControlTestFlatFields, ControlTestTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

type OnEditFunction = (testResult: ControlTestTableFields) => void;

const useGetFieldConfig = (
  onEdit?: OnEditFunction
): TableFields<ControlTestTableFields> => {
  const { getByValue } = useRating('effectiveness');
  const tagField = useGetTagFieldConfig<ControlTestTableFields>();
  const departmentField = useGetDepartmentFieldConfig<ControlTestTableFields>();
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'testResults.columns',
  });
  return useMemo(
    () => ({
      SequentialId: {
        header: stc('id'),
        sortingField: 'SequentialId',
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit?.(item)}>
            {getFriendlyId(Parent_Type_Enum.TestResult, item.SequentialId) ||
              '-'}
          </Link>
        ),
        exportVal: (item) =>
          getFriendlyId(Parent_Type_Enum.TestResult, item.SequentialId) || '-',
      },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit?.(item)}>
            {item.Title}
          </Link>
        ),
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      ParentTitle: {
        header: st('parent'),
        cell: (item) => item.ParentTitle ?? '-',
        maxWidth: MAX_COL_WIDTH,
      },
      TestType: {
        header: st('test_type'),
        cell: (item) => item.TestType || '-',
      },
      TestDate: dateColumn(st('date'), 'TestDate'),
      DesignEffectivenessLabelled: {
        header: st('design_effectiveness'),
        cell: (item) => (
          <SimpleRatingBadge
            rating={getByValue(item.DesignEffectiveness || 0)}
          />
        ),
      },
      PerformanceEffectivenessLabelled: {
        header: st('performance_effectiveness'),
        cell: (item) => (
          <SimpleRatingBadge
            rating={getByValue(item.PerformanceEffectiveness || 0)}
          />
        ),
      },
      OverallEffectivenessLabelled: {
        header: st('overall_effectiveness'),
        cell: (item) => (
          <SimpleRatingBadge
            rating={getByValue(item.OverallEffectiveness || 0)}
          />
        ),
      },
      SubmitterNameLabelled: {
        header: st('submitter'),
        cell: (item) => item.SubmitterNameLabelled || '-',
      },
      //------------------
      CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
      Description: {
        header: stc('description'),
        cell: (item) => item.Description || '-',
        maxWidth: MAX_COL_WIDTH,
      },
      Id: { header: stc('guid') },

      ControlSequentialId: {
        header: st('parent_id'),
        cell: (item) =>
          getFriendlyId(Parent_Type_Enum.Control, item.ControlSequentialId) ||
          '-',
        exportVal: (item) =>
          getFriendlyId(Parent_Type_Enum.Control, item.ControlSequentialId) ||
          '-',
      },
      ParentControlId: {
        header: st('parent_guid'),
        cell: (item) => item.ParentControlId,
      },
      ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
      CreatedByUser: {
        header: stc('created_by_id'),
      },
      CreatedByUserName: {
        header: stc('created_by_username'),
      },
      Overdue: {
        header: 'Overdue',
        cell: yesNoCell('Overdue'),
      },
      FileCount: {
        header: st('associated_files'),
      },
      tags: tagField,
      departments: departmentField,
    }),
    [departmentField, getByValue, onEdit, st, stc, tagField]
  );
};

const useGetControlTableProps = (
  records: ControlTestFlatFields[] | undefined,
  onEdit?: OnEditFunction,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ControlTestTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const fields = useGetFieldConfig(onEdit);
  const labelledFields = useLabelledFields(records);

  return useMemo(
    () => ({
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: <></>,
      storageKey: 'ControlTestRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'SequentialId',
        'Title',
        'ParentTitle',
        'TestType',
        'TestDate',
        'OverallEffectivenessLabelled',
        'SubmitterNameLabelled',
      ],
      fields,
    }),
    [customAttributeSchema, fields, labelledFields, st]
  );
};

export const useGetCollectionTableProps = (
  records: ControlTestFlatFields[] | undefined,
  onEdit?: OnEditFunction,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ControlTestTableFields> => {
  const props = useGetControlTableProps(records, onEdit, customAttributeSchema);
  return useGetTableProps(props);
};

export const useGetControlTestSmartWidgetTableProps = (
  records: ControlTestFlatFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ControlTestTableFields>
): TablePropsWithActions<ControlTestTableFields> => {
  const props = useGetControlTableProps(
    records,
    undefined,
    customAttributeSchema
  );

  return useGetStatelessTableProps<ControlTestTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
