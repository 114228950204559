import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '@/components/Form/ControlledInput';
import { ControlledSwitch } from '@/components/Form/ControlledSwitch/ControlledSwitch';
import { FormField } from '@/components/Form/Form/FormField';

import { dataSources } from '../../UniversalWidget/dataSources';
import { WidgetPropertyFilter } from '../../UniversalWidget/form/WidgetPropertyFilter';
import { SettingsSchema } from '../../UniversalWidget/settingsSchema';

export enum TestIds {
  Filtering = 'filtering',
}

export const WidgetSettingsFormFields = () => {
  const { control, watch } = useFormContext<SettingsSchema>();
  const { t } = useTranslation('common', {
    keyPrefix: 'dashboard.widgetSettings.fields',
  });

  const customTitle = watch('customTitle');
  return (
    <div>
      <Controller
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <FormField
              testId={TestIds.Filtering}
              label={'Filtering (optional)'}
            >
              <WidgetPropertyFilter
                value={value}
                onChange={onChange}
                dataSource={dataSources.risk}
              />
            </FormField>
            <div className={'mb-4'}>
              <ControlledSwitch
                name={'customTitle'}
                label={t('customTitle')}
                control={control}
              />
            </div>
            {customTitle && (
              <ControlledInput
                control={control}
                name={'title'}
                label={t('customTitle')}
              />
            )}
          </>
        )}
        name={'filtering'}
      />
    </div>
  );
};
