import i18n from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';
import { useGetObligationSmartWidgetTableProps } from 'src/pages/compliance/obligations/config';
import { ObligationTableFields } from 'src/pages/compliance/obligations/types';

import { GetObligationsDocument } from '@/generated/graphql.typed';
import { StatefulTableOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { obligationRegister } from '@/utils/urls';

import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: (hasPermission, isFeatureVisibleToOrg) =>
    !!isFeatureVisibleToOrg('compliance'),
  documentNode: GetObligationsDocument,
  defaultVariables: { where: {} },
  useTablePropsHook: (
    data,
    options: StatefulTableOptions<ObligationTableFields>
  ) => {
    const assessmentResults = useMemo(
      () =>
        data?.assessment_result_parent.map(
          (ar) => ar.obligationAssessmentResult
        ),
      [data?.assessment_result_parent]
    );
    return useGetObligationSmartWidgetTableProps(
      data?.obligation,
      assessmentResults,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    );
  },
  entityNamePlural: 'obligation_other',
  entityNameSingular: 'obligation_one',
  fields: 'obligations.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => obligationRegister(filters),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<ObligationTableFields>(),
    {
      id: 'title',
      name: () => i18n.t('obligations.columns.Title'),
      categoryGetter: (item) => ({
        key: item.Title,
        label: item.Title,
      }),
      clickthroughFilter: defaultClickthroughFilter('Title'),
    },
    {
      id: 'typeLabel',
      name: () => i18n.t('obligations.columns.Type'),
      categoryGetter: (item) => ({
        key: item.TypeLabel,
        label: item.TypeLabel,
      }),
      clickthroughFilter: defaultClickthroughFilter('TypeLabel'),
    },
    {
      id: 'owner',
      name: () => i18n.t('obligations.columns.Owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'createdDate',
      name: () => i18n.t('obligations.columns.CreatedAt'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
