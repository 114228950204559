import dayjs from 'dayjs';
import _ from 'lodash';
import { useMemo } from 'react';

import { Appetite_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import { AppetiteFields, AppetiteTableFields } from './types';

const getAppetiteStatus = (item: AppetiteFields, activeAppetites: string[]) => {
  if (dayjs().isBefore(dayjs(item.EffectiveDate))) {
    return 'upcoming';
  }

  if (activeAppetites.includes(item.Id)) {
    return 'active';
  }

  if (dayjs().isAfter(dayjs(item.EffectiveDate))) {
    return 'archived';
  }
};

export const getActiveAppetites = (
  items:
    | Pick<
        AppetiteFields,
        'AppetiteType' | 'EffectiveDate' | 'parents' | 'Id'
      >[]
    | undefined
) => {
  if (!items) return [];

  const nonFutureAppetites = items.filter((i) =>
    dayjs().isAfter(dayjs(i.EffectiveDate))
  );

  const groups = _.groupBy(nonFutureAppetites, (item) => item.AppetiteType);
  const risk = groups[Appetite_Type_Enum.Risk]?.[0];
  const impactGroups = _.groupBy(
    groups[Appetite_Type_Enum.Impact],
    (item) => item.parents.find((p) => p.impact)?.impact?.Id
  );

  return [
    risk?.Id,
    ..._.keys(impactGroups)
      .map((k) => impactGroups[k]?.[0])
      .map((i) => i.Id),
  ];
};

export const useLabelledFields = (records: AppetiteFields[] | undefined) => {
  const { getLabel } = useRating('risk_appetite');
  const { getLabel: getImpactAppetiteLabel } = useRating('impact_appetite');
  const { getLabel: getLikelihoodAppetiteLabel } = useRating(
    'likelihood_appetite'
  );
  const { getLabel: getStatusLabel } = useRating('appetite_status');

  return useMemo<AppetiteTableFields[] | undefined>(() => {
    const activeAppetites = getActiveAppetites(records);
    return records?.map((d) => {
      const Status = getAppetiteStatus(d, activeAppetites);
      return {
        ...d,
        ImpactId: d.parents.find((p) => p.impact)?.impact?.Id,
        ImpactName: d.parents.find((p) => p.impact)?.impact?.Name,
        LowerAppetite: d.LowerAppetite,
        UpperAppetite: d.UpperAppetite,
        Status,
        LowerAppetiteLabelled: d.LowerAppetite
          ? getLabel(d.LowerAppetite)
          : 'Undefined',
        UpperAppetiteLabelled: d.UpperAppetite
          ? getLabel(d.UpperAppetite)
          : 'Undefined',
        ImpactAppetiteLabelled: d.ImpactAppetite
          ? getImpactAppetiteLabel(d.ImpactAppetite)
          : 'Undefined',
        StatusLabelled: getStatusLabel(Status) ?? '-',
        LikelihoodAppetiteLabelled:
          getLikelihoodAppetiteLabel(d.LikelihoodAppetite) ?? '-',
      };
    });
  }, [
    records,
    getLabel,
    getImpactAppetiteLabel,
    getStatusLabel,
    getLikelihoodAppetiteLabel,
  ]);
};
