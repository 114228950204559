import _ from 'lodash';
import { useMemo } from 'react';
import {
  getImpactPerformanceRating,
  getImpactPerformanceScore,
} from 'src/pages/impacts/ratings/performanceCalculation';
import { ImpactRatingStatus } from 'src/pages/impacts/ratings/ratingStatus';

import {
  GetAppetitesGroupedByImpactQuery,
  GetImpactRatingsByImpactIdQuery,
} from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';

import { ImpactRatingTableFields } from './types';

export const useLabelledFields = (
  data: GetImpactRatingsByImpactIdQuery['impact_rating'] | undefined,
  impactAppetites: GetAppetitesGroupedByImpactQuery['impact'] | undefined
) => {
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const getEntityInfo = useEntityInfo();
  return useMemo<ImpactRatingTableFields[]>(() => {
    const latestLookup: { [id: string]: boolean } = {};

    // sort by test date so we can easily calculate if item is active
    const sortedRatings = [...(data ?? [])].sort(
      (a, b) => new Date(b.TestDate).getTime() - new Date(a.TestDate).getTime()
    );

    return (
      sortedRatings?.map((ir) => {
        const isArchived = !!latestLookup[ir.RatedItemId];
        if (!isArchived) {
          latestLookup[ir.RatedItemId] = true;
        }

        const impactAppetite = impactAppetites?.find(
          (ia) => ia.Id === ir.ImpactId
        );
        const impactAppetiteForRatedItem = impactAppetite?.appetites.find((a) =>
          a.appetite?.parents.find((p) => p.risk?.Id === ir.RatedItemId)
        );

        return {
          ...ir,
          LikelihoodLabel: getLikelihoodLabel(ir.Likelihood),
          Performance: getImpactPerformanceRating(
            ir,
            impactAppetiteForRatedItem?.appetite?.ImpactAppetite
          ),
          PerformanceScore: _.isNil(
            impactAppetiteForRatedItem?.appetite?.ImpactAppetite
          )
            ? null
            : getImpactPerformanceScore(
                ir.Rating,
                impactAppetiteForRatedItem?.appetite?.ImpactAppetite
              ),
          Type: getEntityInfo(ir.ratedItem.ObjectType).singular,
          Status: isArchived
            ? ImpactRatingStatus.Archived
            : ImpactRatingStatus.Active,
        };
      }) || []
    );
  }, [data, impactAppetites, getLikelihoodLabel, getEntityInfo]);
};
