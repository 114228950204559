import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Parent_Type_Enum, useGetDocumentsQuery } from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import CustomisableRibbon from '@/components/CustomisableRibbon/CustomisableRibbon';
import { getCounter } from '@/utils/collectionUtils';
import { addPolicyUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './config';
import { useGetDefaultRibbonFilters } from './defaultRibbonFilters';

const Page: FC = () => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'policy',
  });
  const defaultRibbonFilters = useGetDefaultRibbonFilters();
  const { t } = useTranslation(['common'], {});
  const title = st('register_title');
  const { addNotification } = useNotifications();
  const { data, loading } = useGetDocumentsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.document,
    data?.assessment_result_parent.map((ar) => ar.documentAssessmentResult),
    data?.form_configuration?.[0]?.customAttributeSchema
  );
  return (
    <PageLayout
      helpTranslationKey="policy.registerHelp"
      title={title}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission="insert:document">
            <Button variant="primary" href={addPolicyUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <CustomisableRibbon
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        filteringOptions={tableProps.propertyFilterProps.filteringOptions}
        parentType={Parent_Type_Enum.Document}
        defaultFilters={defaultRibbonFilters}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
