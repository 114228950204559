import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { IndicatorTableFields } from './types';

type Props = {
  items: readonly IndicatorTableFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const IndicatorSummary: FC<Props> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'indicators.summary_titles',
  });
  const { t: stc } = useTranslation(['taxonomy']);
  const { getLabel: getConformanceLabel } = useRating(
    'indicator_conformance_status'
  );
  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'ParentType',
              value: stc('risk_one'),
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('risk_indicators')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'ParentType',
              value: stc('control_one'),
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('control_indicators')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'ConformanceLabelled',
              value: getConformanceLabel(2),
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('within_tolerance')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'ConformanceLabelled',
              value: getConformanceLabel(1),
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('outside_tolerance')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('total')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
