import { SpaceBetween } from '@cloudscape-design/components-themed';
import {
  Activity,
  AlertTriangle,
  Asterisk02,
  Certificate02,
  CheckCircleBroken,
  CheckVerified03,
  FileCheck01,
  Settings04,
  Tool02,
  Zap,
} from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import Link from '@/components/Link';
import {
  GetLinkedItemsQuery,
  OwnerGroupPartsFragment,
  OwnerPartsFragment,
  Parent_Type_Enum,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields } from '@/utils/table/types';
import {
  acceptanceRegisterUrl,
  actionDetailsUrl,
  appetiteDetailsUrl,
  assessmentActivitiesDetailsUrl,
  assessmentDetailsUrl,
  causeDetailsUrl,
  complianceMonitoringAssessmentActivitiesDetailsUrl,
  consequenceDetailsUrl,
  controlDetailsUrl,
  controlGroupDetailsUrl,
  impactDetailsUrl,
  indicatorDetailsUrl,
  internalAuditDetailsUrl,
  internalAuditReportActivitiesDetailsUrl,
  internalAuditReportDetailsUrl,
  issueDetailsUrl,
  obligationDetailsUrl,
  policyDetailsUrl,
  riskDetailsUrl,
  thirdPartyDetailsUrl,
} from '@/utils/urls';

import { ObjectWithContributors } from '../../rbac/Permission';
import { LinkedItemsTableFields } from './types';

const useGetProperties = () => {
  const { t } = useTranslation('taxonomy');

  return (item: LinkedItemsTableFields) => {
    if (item.asSource) {
      if (item.target_risk)
        return {
          typelabel: i18n.format(t('risk_one'), 'capitalize'),
          title: item.target_risk.Title,
          url: riskDetailsUrl(item.target_risk.Id),
          icon: <Zap />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Risk,
            item.target_risk.SequentialId
          ),
        };
      if (item.target_third_party)
        return {
          typelabel: i18n.format(t('third_party_one'), 'capitalize'),
          title: item.target_third_party.Title,
          url: thirdPartyDetailsUrl(item.target_third_party.Id),
          icon: <Tool02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.ThirdParty,
            item.target_third_party.SequentialId
          ),
        };
      if (item.target_control) {
        return {
          typelabel: i18n.format(t('control_one'), 'capitalize'),
          title: item.target_control.Title,
          url: controlDetailsUrl(
            item.target_control.Id,
            item.parentId,
            item.parentType
          ),
          icon: <Settings04 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Control,
            item.target_control.SequentialId
          ),
        };
      }
      if (item.target_control_group) {
        return {
          typelabel: i18n.format(
            `${t('control_one')} ${t('control_group_one')}`,
            'capitalize'
          ),
          title: item.target_control_group.Title,
          url: controlGroupDetailsUrl(item.Target),
          icon: <Settings04 />,
        };
      }
      if (item.target_assessment)
        return {
          typelabel: i18n.format(t('assessment_one'), 'capitalize'),
          title: item.target_assessment.Title,
          url: assessmentDetailsUrl(item.target_assessment.Id),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Assessment,
            item.target_assessment.SequentialId
          ),
        };
      if (item.target_internal_audit_report)
        return {
          typelabel: i18n.format(t('internal_audit_report_one'), 'capitalize'),
          title: item.target_internal_audit_report.Title,
          url: internalAuditReportDetailsUrl(
            item.target_internal_audit_report.Id
          ),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.InternalAuditReport,
            item.target_internal_audit_report.SequentialId
          ),
        };

      if (item.target_internal_audit_entity)
        return {
          typelabel: i18n.format(t('internal_audit_one'), 'capitalize'),
          title: item.target_internal_audit_entity.Title,
          url: internalAuditDetailsUrl(item.target_internal_audit_entity.Id),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.InternalAuditEntity,
            item.target_internal_audit_entity.SequentialId
          ),
        };

      if (item.target_assessment_activity) {
        let activityUrl;
        if (item.target_assessment_activity?.parentAssessment?.Id) {
          activityUrl = assessmentActivitiesDetailsUrl;
        }
        if (item.target_assessment_activity?.parentInternalAuditReport?.Id) {
          activityUrl = internalAuditReportActivitiesDetailsUrl;
        }
        if (
          item.target_assessment_activity?.parentComplianceMonitoringAssessment
            ?.Id
        ) {
          activityUrl = complianceMonitoringAssessmentActivitiesDetailsUrl;
        }
        return {
          typelabel: i18n.format(t('assessment_activity_one'), 'capitalize'),
          title: item.target_assessment_activity.Title,
          url: activityUrl
            ? `${activityUrl(
                item.target_assessment_activity.ParentId,
                item.target_assessment_activity.Id
              )}`
            : undefined,
          icon: <Settings04 />,
        };
      }
      if (item.target_impact)
        return {
          typelabel: i18n.format(t('impact_one'), 'capitalize'),
          title: item.target_impact.Name,
          url: impactDetailsUrl(item.target_impact.Id),
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Impact,
            item.target_impact.SequentialId
          ),
        };
      if (item.target_impact_rating)
        return {
          typelabel: i18n.format(t('impact_rating_one'), 'capitalize'),
          title: item.target_impact_rating.impact.Name,
          url: `${impactDetailsUrl(
            item.target_impact_rating.impact.Id
          )}/rating`,
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.ImpactRating,
            item.target_impact_rating.impact.SequentialId
          ),
        };
      if (item.target_obligation_impact)
        return {
          typelabel: `${i18n.format(
            t('obligation_one'),
            'capitalize'
          )} ${i18n.format(t('impact_one'), '')}`,
          title: item.target_obligation_impact.Description,
          url: `${obligationDetailsUrl(
            item.target_obligation_impact.ParentObligationId
          )}/impact`,
          icon: <Asterisk02 />,
        };
      if (item.target_action)
        return {
          typelabel: i18n.format(t('action_one'), 'capitalize'),
          title: item.target_action.Title,
          url: actionDetailsUrl(item.target_action.Id),
          icon: <CheckCircleBroken />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Action,
            item.target_action.SequentialId
          ),
        };
      if (item.target_action_update)
        return {
          typelabel: i18n.format(t('action_update_one'), 'capitalize'),
          title: item.target_action_update.Title,
          url: `${actionDetailsUrl(
            item.target_action_update.ParentActionId
          )}/updates`,
          icon: <CheckCircleBroken />,
        };
      if (item.target_indicator)
        return {
          typelabel: i18n.format(t('indicator_one'), 'capitalize'),
          title: item.target_indicator.Title,
          url: indicatorDetailsUrl(item.target_indicator.Id),
          icon: <Activity />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Indicator,
            item.target_indicator.SequentialId
          ),
        };
      if (item.target_acceptance)
        return {
          typelabel: i18n.format(t('acceptance_one'), 'capitalize'),
          title: item.target_acceptance.Title,
          url: acceptanceRegisterUrl(),
          icon: <Zap />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Acceptance,
            item.target_acceptance.SequentialId
          ),
        };
      if (item.target_appetite)
        return {
          typelabel: i18n.format(t('appetite_one'), 'capitalize'),
          // @TODO: appetites don't have a title, work out how to display them (data set, lower / upper appetite etc, friendly ID etc)
          title: 'Appetite',
          url: appetiteDetailsUrl(item.target_appetite.Id),
          icon: <Zap />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Appetite,
            item.target_appetite.SequentialId
          ),
        };
      if (item.target_issue)
        return {
          typelabel: i18n.format(t('issue_one'), 'capitalize'),
          title: item.target_issue.Title,
          url: issueDetailsUrl(item.target_issue.Id),
          icon: <AlertTriangle />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Issue,
            item.target_issue.SequentialId
          ),
        };
      if (item.target_issue_update)
        return {
          typelabel: i18n.format(t('issue_update_one'), 'capitalize'),
          title: item.target_issue_update.Title,
          url: `${issueDetailsUrl(
            item.target_issue_update.ParentIssueId
          )}/updates`,
          icon: <AlertTriangle />,
        };
      if (item.target_document)
        return {
          typelabel: i18n.format(t('document_one'), 'capitalize'),
          title: item.target_document.Title,
          url: policyDetailsUrl(item.target_document.Id),
          icon: <FileCheck01 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Document,
            item.target_document.SequentialId
          ),
        };
      if (item.target_obligation)
        return {
          typelabel: i18n.format(t('obligation_one'), 'capitalize'),
          title: item.target_obligation.Title,
          url: obligationDetailsUrl(item.target_obligation.Id),
          icon: <CheckVerified03 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Obligation,
            item.target_obligation.SequentialId
          ),
        };
      if (item.target_consequence)
        return {
          typelabel: i18n.format(t('consequence_one'), 'capitalize'),
          title: item.target_consequence.Title,
          url: consequenceDetailsUrl(
            item.target_consequence.ParentIssueId,
            item.target_consequence.Id
          ),
          icon: <AlertTriangle />,
        };
      if (item.target_cause)
        return {
          typelabel: i18n.format(t('cause_one'), 'capitalize'),
          title: item.target_cause.Title,
          url: causeDetailsUrl(
            item.target_cause.ParentIssueId,
            item.target_cause.Id
          ),
          icon: <AlertTriangle />,
        };
      if (item.target_test_result)
        return {
          typelabel: i18n.format(t('result_one'), 'capitalize'),
          title: item.target_test_result.Title,
          url: `${controlDetailsUrl(item.Source)}/performance`,
          icon: <Settings04 />,
        };
    }
    if (item.asTarget) {
      if (item.source_risk)
        return {
          typelabel: i18n.format(t('risk_one'), 'capitalize'),
          title: item.source_risk.Title,
          url: riskDetailsUrl(item.source_risk.Id),
          icon: <Zap />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Risk,
            item.source_risk.SequentialId
          ),
        };
      if (item.source_third_party)
        return {
          typelabel: i18n.format(t('third_party_one'), 'capitalize'),
          title: item.source_third_party.Title,
          url: thirdPartyDetailsUrl(item.source_third_party.Id),
          icon: <Tool02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.ThirdParty,
            item.source_third_party.SequentialId
          ),
        };
      if (item.source_control)
        return {
          typelabel: i18n.format(t('control_one'), 'capitalize'),
          title: item.source_control.Title,
          url: controlDetailsUrl(item.source_control.Id),
          icon: <Settings04 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Control,
            item.source_control.SequentialId
          ),
        };
      if (item.source_control_group) {
        return {
          typelabel: i18n.format(
            `${t('control_one')} ${t('control_group_one')}`,
            'capitalize'
          ),
          title: item.source_control_group.Title,
          url: controlGroupDetailsUrl(item.Source),
          icon: <Settings04 />,
        };
      }
      if (item.source_assessment)
        return {
          typelabel: i18n.format(t('assessment_one'), 'capitalize'),
          title: item.source_assessment.Title,
          url: assessmentDetailsUrl(item.source_assessment.Id),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Assessment,
            item.source_assessment.SequentialId
          ),
        };

      if (item.source_internal_audit_report)
        return {
          typelabel: i18n.format(t('internal_audit_report_one'), 'capitalize'),
          title: item.source_internal_audit_report.Title,
          url: internalAuditDetailsUrl(item.source_internal_audit_report.Id),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.InternalAuditReport,
            item.source_internal_audit_report.SequentialId
          ),
        };

      if (item.source_internal_audit_entity)
        return {
          typelabel: i18n.format(t('internal_audit_one'), 'capitalize'),
          title: item.source_internal_audit_entity.Title,
          url: internalAuditDetailsUrl(item.source_internal_audit_entity.Id),
          icon: <Certificate02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.InternalAuditEntity,
            item.source_internal_audit_entity.SequentialId
          ),
        };

      if (item.source_assessment_activity) {
        let activityUrl;
        if (item.source_assessment_activity?.parentAssessment?.Id) {
          activityUrl = assessmentActivitiesDetailsUrl;
        }
        if (item.source_assessment_activity?.parentInternalAuditReport?.Id) {
          activityUrl = internalAuditReportActivitiesDetailsUrl;
        }
        if (
          item.source_assessment_activity?.parentComplianceMonitoringAssessment
            ?.Id
        ) {
          activityUrl = complianceMonitoringAssessmentActivitiesDetailsUrl;
        }
        return {
          typelabel: i18n.format(t('assessment_activity_one'), 'capitalize'),
          title: item.source_assessment_activity.Title,
          url: activityUrl
            ? `${activityUrl(
                item.source_assessment_activity.ParentId,
                item.source_assessment_activity.Id
              )}`
            : undefined,
          icon: <Settings04 />,
        };
      }

      if (item.source_impact)
        return {
          typelabel: i18n.format(t('impact_one'), 'capitalize'),
          title: item.source_impact.Name,
          url: impactDetailsUrl(item.source_impact.Id),
          icon: <Asterisk02 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Impact,
            item.source_impact.SequentialId
          ),
        };
      if (item.source_action)
        return {
          typelabel: i18n.format(t('action_one'), 'capitalize'),
          title: item.source_action.Title,
          url: actionDetailsUrl(item.source_action.Id),
          icon: <CheckCircleBroken />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Action,
            item.source_action.SequentialId
          ),
        };
      if (item.source_indicator)
        return {
          typelabel: i18n.format(t('indicator_one'), 'capitalize'),
          title: item.source_indicator.Title,
          url: indicatorDetailsUrl(item.source_indicator.Id),
          icon: <Activity />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Indicator,
            item.source_indicator.SequentialId
          ),
        };
      if (item.source_issue)
        return {
          typelabel: i18n.format(t('issue_one'), 'capitalize'),
          title: item.source_issue.Title,
          url: issueDetailsUrl(item.source_issue.Id),
          icon: <AlertTriangle />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Issue,
            item.source_issue.SequentialId
          ),
        };
      if (item.source_document)
        return {
          typelabel: i18n.format(t('document_one'), 'capitalize'),
          title: item.source_document.Title,
          url: policyDetailsUrl(item.source_document.Id),
          icon: <FileCheck01 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Document,
            item.source_document.SequentialId
          ),
        };
      if (item.source_obligation)
        return {
          typelabel: i18n.format(t('obligation_one'), 'capitalize'),
          title: item.source_obligation.Title,
          url: obligationDetailsUrl(item.source_obligation.Id),
          icon: <CheckVerified03 />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Obligation,
            item.source_obligation.SequentialId
          ),
        };
      if (item.source_acceptance)
        return {
          typelabel: i18n.format(t('acceptance_one'), 'capitalize'),
          title: item.source_acceptance.Title,
          url: acceptanceRegisterUrl(),
          icon: <Zap />,
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Acceptance,
            item.source_acceptance.SequentialId
          ),
        };
      if (item.source_appetite)
        return {
          typelabel: i18n.format(t('appetite_one'), 'capitalize'),
          title: item.source_appetite.Id,
          url: appetiteDetailsUrl(item.source_appetite.Id),
          sequentialId: getFriendlyId(
            Parent_Type_Enum.Appetite,
            item.source_appetite.SequentialId
          ),
          icon: <Zap />,
        };
      if (item.source_consequence)
        return {
          typelabel: i18n.format(t('consequence_one'), 'capitalize'),
          title: item.source_consequence.Title,
          url: consequenceDetailsUrl(
            item.source_consequence.ParentIssueId,
            item.source_consequence.Id
          ),
          icon: <AlertTriangle />,
        };
      if (item.source_cause)
        return {
          typelabel: i18n.format(t('cause_one'), 'capitalize'),
          title: item.source_cause.Title,
          url: causeDetailsUrl(
            item.source_cause.ParentIssueId,
            item.source_cause.Id
          ),
          icon: <AlertTriangle />,
        };
    }
  };
};

const getOwners = (item: LinkedItemsTableFields) => {
  for (const val of Object.values(item)) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      val?.hasOwnProperty('owners') &&
      // eslint-disable-next-line no-prototype-builtins
      val?.hasOwnProperty('ownerGroups')
    ) {
      return getAllOwnersCellValue(
        val as {
          owners: OwnerPartsFragment[];
          ownerGroups: OwnerGroupPartsFragment[];
        }
      );
    }
  }
  return [];
};

const useGetLabelledFields = (
  data: GetLinkedItemsQuery | undefined,
  parentType: Parent_Type_Enum | undefined,
  parent: ObjectWithContributors
) => {
  const getProperties = useGetProperties();
  const hasAppetiteLinks = useIsFeatureVisibleToOrg('appetite_links');
  const hasThirdParties = useIsFeatureVisibleToOrg('third_party');
  return useMemo<LinkedItemsTableFields[]>(() => {
    return [
      ...(data?.as_source ?? [])
        .filter(
          (li) =>
            li.target_acceptance ??
            li.target_action ??
            li.target_action_update ??
            (hasAppetiteLinks ? li.target_appetite : undefined) ??
            (hasThirdParties ? li.target_third_party : undefined) ??
            li.target_assessment ??
            li.target_internal_audit_report ??
            li.target_assessment_activity ??
            li.target_cause ??
            li.target_consequence ??
            li.target_control ??
            li.target_control_group ??
            li.target_document ??
            li.target_impact ??
            li.target_impact_rating ??
            li.target_indicator ??
            li.target_issue ??
            li.target_issue_update ??
            li.target_obligation ??
            li.target_obligation_impact ??
            li.target_risk ??
            li.target_test_result
        )
        .map((li) => {
          const item = {
            ...li,
            SequentialId: '',
            asSource: true,
            parentId: parent.Id,
            allOwners: [],
            parentType,
            Name: '',
            Type: '',
            url: '',
          };

          const allOwners = getOwners(item);
          const props = getProperties(item);

          return {
            ...item,
            SequentialId: props?.sequentialId ?? '-',
            Name: props?.title ?? '-',
            Type: props?.typelabel ?? '-',
            url: props?.url ?? '',
            allOwners,
          };
        }),
      ...(data?.as_target ?? []).map((li) => {
        const item = {
          ...li,
          SequentialId: '',
          asTarget: true,
          parentId: parent.Id,
          parentType,
          allOwners: [],
          Name: '',
          Type: '',
          url: '',
        };

        const allOwners = getOwners(item);
        const props = getProperties(item);

        return {
          ...item,
          SequentialId: props?.sequentialId ?? '-',
          Name: props?.title ?? '-',
          Type: props?.typelabel ?? '-',
          url: props?.url ?? '',
          allOwners,
        };
      }),
    ];
  }, [
    data?.as_source,
    data?.as_target,
    hasThirdParties,
    hasAppetiteLinks,
    parent.Id,
    parentType,
    getProperties,
  ]);
};

const useColumnDefinitions = () => {
  const getProperties = useGetProperties();
  const allOwners = useGetOwnersFieldConfig<LinkedItemsTableFields>();
  const columnDefinitions: TableFields<LinkedItemsTableFields> = {
    SequentialId: {
      id: 'id',
      header: 'ID',
      isRowHeader: true,
    },
    Name: {
      id: 'name',
      header: 'Name',
      cell: (item) => {
        return (
          <Link variant="secondary" href={item.url}>
            {item.Name}
          </Link>
        );
      },
      isRowHeader: true,
    },
    Type: {
      id: 'type',
      header: 'Type',
      cell: (item) => {
        const props = getProperties(item);
        return (
          <SpaceBetween size="s" direction="horizontal">
            <div>{props?.icon}</div>
            <div>{item.Type}</div>
          </SpaceBetween>
        );
      },
      isRowHeader: true,
    },
    allOwners,
  };
  return columnDefinitions;
};

export const useGetLinkedItemsTableProps = (
  data: GetLinkedItemsQuery | undefined,
  parentType: Parent_Type_Enum | undefined,
  parent: ObjectWithContributors
) => {
  const { t } = useTranslation('common', { keyPrefix: 'linkedItems' });
  const labelledFields = useGetLabelledFields(data, parentType, parent);

  const columnDefinitions = useColumnDefinitions();

  return useGetTableProps({
    data: labelledFields,
    entityLabel: t('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'LinkedItemsTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: ['SequentialId', 'Name', 'Type', 'allOwners'],
    fields: columnDefinitions,
  });
};
