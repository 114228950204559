import { FC } from 'react';

import { useGetThirdPartyByIdQuery } from '@/generated/graphql';

type Props = {
  id: string | undefined;
};

const Breadcrumb: FC<Props> = ({ id }) => {
  if (!id) {
    throw new Error('Missing id param');
  }
  const { data } = useGetThirdPartyByIdQuery({
    variables: {
      Id: id,
    },
  });
  return <>{data?.third_party?.Title || ''}</>;
};

export default Breadcrumb;
