import i18n from 'i18next';
import { DashboardFilter } from 'src/context/DashboardFilter';

import { GetAcceptancesDocument } from '@/generated/graphql.typed';
import { acceptanceRegisterUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from '../../../acceptances/config';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { convertDateRangeValues } from '../../widgets/filterHelpers';
import { createDataSource } from '../createDataSource';
import { defaultClickthroughFilter } from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  documentNode: GetAcceptancesDocument,
  defaultVariables: { where: {} },
  useTablePropsHook: (data) =>
    useGetCollectionTableProps(
      data?.acceptance,
      data?.form_configuration[0]?.customAttributeSchema
    ),
  entityNamePlural: 'acceptance_other',
  entityNameSingular: 'acceptance_one',
  fields: 'acceptances.fields',
  clickThroughUrl: (filters) => acceptanceRegisterUrl({ filtering: filters }),
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({
      where: { parents: { risk: { tags: tagsFilter(tags) } } },
    }),
    departmentsFilter: (departments) => ({
      where: {
        parents: { risk: { departments: departmentsFilter(departments) } },
      },
    }),
    dateFilter: (dateRange, precision) => ({
      where: {
        DateAcceptedFrom: dateRangeFilter(dateRange, precision, 'gte'),
        DateAcceptedTo: dateRangeFilter(dateRange, precision, 'lte'),
      },
    }),
    dateClickthroughFilter: (filter: DashboardFilter['dateRange']) => {
      const { startDate, endDate } = convertDateRangeValues(filter);

      return startDate && endDate
        ? [
            {
              propertyKey: 'DateAcceptedFrom',
              operator: '>=',
              value: startDate.toISOString(),
            } as const,
            {
              propertyKey: 'DateAcceptedTo',
              operator: '<=',
              value: endDate.toISOString(),
            } as const,
          ]
        : [];
    },
  },
  categoryGetters: [
    {
      id: 'status',
      name: () => i18n.t('acceptances.columns.status'),
      categoryGetter: (item) => ({
        key: item.Status,
        label: item.StatusLabelled,
      }),
      clickthroughFilter: defaultClickthroughFilter('StatusLabelled'),
      ratingColourKey: 'acceptance_status',
    },
  ],
});
