import { FC, ReactNode } from 'react';

import {
  Access_Type_Enum,
  Contributor_Type_Enum,
  GetRoleAccessQuery,
  Parent_Type_Enum,
} from '@/generated/graphql';
import useRisksmartUser from '@/hooks/useRisksmartUser';

import { useRoleAccess } from './useRoleAccess';

export type ParentType = `${Parent_Type_Enum}`;
type AccessType = `${Access_Type_Enum}`;
export type ObjectAccess = `${AccessType}:${ParentType}`;

export type ObjectWithContributors = {
  Id: string;
  ancestorContributors: {
    UserId?: string | null;
    ContributorType?: string | null; //  This is a Contributor_Type_Enum,
  }[];
};

type AllObjectAccessOptions = {
  /** This type of object. */
  objectType: Parent_Type_Enum;
  /** The access required e.g can I "UPDATE" this risk */
  accessType: Access_Type_Enum;
};

type ObjectAccessOptions = AllObjectAccessOptions & {
  /**
   * This can be the object on which we are checking permissions e.g. can I
   * update a Risk Or the parent of the object of which we are checking
   * permissions e.g. can I insert a control under a Risk It is used to check
   * whether the user is a contributor or owner of the object. If the user is a
   * contributor/owner of this object, they will also be a contributor/owner of
   * any descendant objects
   */
  parentObject?: ObjectWithContributors | null;

  /**
   * When true, checks whether the users role can have access to the object type
   * and access type, if they are set as a contributor/owner of a specific
   * object. This is useful if you want functionality listing objects without
   * first attempting to retrieve those objects to check for permissions for
   * example, standard users can view the risk register, because if they are a
   * contributor or owner of a risk, they'd like to see them in this location.
   * However, they may not actually have access to any risks.
   */
  canHaveAccessAsContributor?: boolean;
};

export type HasPermission = (
  /**
   * The permission required to access the object e.g. update:risk
   *
   * If an array of permissions is provided, the user must have at least one of
   * the permissions to pass the check
   */
  permission: ObjectAccess | ObjectAccess[],
  /**
   * The object on which we are checking permissions e.g. can I update a Risk OR
   * can I insert a control under a Risk
   */
  parentObject?: ObjectWithContributors | null,
  /**
   * When true, checks whether the user can be granted access even if they are
   * only a contributor of the object
   */
  canHaveAccessAsContributor?: boolean
) => boolean;

export const useHasPermission: HasPermission = (
  permission,
  parentObject,
  canHaveAccessAsContributor
) => {
  const permissionArray = Array.isArray(permission) ? permission : [permission];
  const parsed = permissionArray.map((p) => p.split(':'));

  if (parsed.some((p) => p.length !== 2)) {
    throw new Error('Invalid permission');
  }

  const { user } = useRisksmartUser();

  const roleAccess = useRoleAccess();
  if (!roleAccess) {
    return false;
  }

  return parsed.some(([accessType, objectType]) => {
    return hasPermission({
      parentObject,
      userId: user?.userId,
      roleAccess,
      objectType: objectType as Parent_Type_Enum,
      accessType: accessType as Access_Type_Enum,
      canHaveAccessAsContributor,
    });
  });
};

export const useHasPermissionLazy = (): HasPermission => {
  const { user } = useRisksmartUser();

  const roleAccess = useRoleAccess();

  return (
    permission: ObjectAccess | ObjectAccess[],
    parentObject?: ObjectWithContributors | null,
    canHaveAccessAsContributor?: boolean
  ) => {
    const permissionArray = Array.isArray(permission)
      ? permission
      : [permission];
    const parsed = permissionArray.map((p) => p.split(':'));

    if (parsed.some((p) => p.length !== 2)) {
      throw new Error('Invalid permission');
    }

    if (!roleAccess) {
      return false;
    }

    return parsed.some(([accessType, objectType]) => {
      return hasPermission({
        parentObject,
        userId: user?.userId,
        roleAccess,
        objectType: objectType as Parent_Type_Enum,
        accessType: accessType as Access_Type_Enum,
        canHaveAccessAsContributor,
      });
    });
  };
};

export type HasAccessOptions = ObjectAccessOptions & {
  roleAccess: GetRoleAccessQuery['role_access'];
  userId?: string;
};

export const hasPermission = ({
  parentObject,
  accessType,
  objectType,
  roleAccess,
  userId,
  canHaveAccessAsContributor,
}: HasAccessOptions) => {
  const userContributions =
    (parentObject?.ancestorContributors ?? [])
      .filter((p) => p.UserId === userId)
      .map((p) => p.ContributorType) ?? [];
  return (
    roleAccess.filter(
      (ra) =>
        (canHaveAccessAsContributor ||
          ra.ContributorType === Contributor_Type_Enum.Any ||
          userContributions.includes(ra.ContributorType!)) &&
        ra.AccessType === accessType &&
        ra.ObjectType === objectType
    ).length > 0
  );
};

export const Permission: FC<{
  children: ReactNode;
  parentObject?: ObjectWithContributors | null;
  permission: ObjectAccess;
  canHaveAccessAsContributor?: boolean;
}> = ({ children, permission, parentObject, canHaveAccessAsContributor }) => {
  const permissionGranted = useHasPermission(
    permission,
    parentObject,
    canHaveAccessAsContributor
  );
  return permissionGranted ? <>{children}</> : <></>;
};
