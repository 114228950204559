import { FC } from 'react';

import useRisksmartUser from '../hooks/useRisksmartUser';

interface Props {
  loginUrl: () => string | string;
}

const Page: FC<Props> = ({ loginUrl }) => {
  const { isLoading, logout } = useRisksmartUser();

  // clear data from current session
  window.sessionStorage.clear();

  if (isLoading) {
    return <></>;
  }

  logout({
    logoutParams: {
      returnTo:
        window.location.origin +
        (typeof loginUrl === 'function' ? loginUrl() : loginUrl),
    },
  });

  return <></>;
};

export default Page;
