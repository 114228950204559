import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';
import { ImpactPerformanceRating } from 'src/pages/impacts/ratings/performanceCalculation';
import { ImpactRatingStatus } from 'src/pages/impacts/ratings/ratingStatus';
import { ObjectWithContributors, Permission } from 'src/rbac/Permission';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  GetAppetitesByParentIdQuery,
  GetImpactRatingsByRatedItemIdQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { CollectionData } from '@/utils/collectionUtils';
import { toLocalDate } from '@/utils/dateUtils';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';

import { useLabelledFields } from './useLabelledFields';

export type ImpactRatingTableFields = CollectionData<
  GetImpactRatingsByRatedItemIdQuery['impact_rating'][number] & {
    Performance: string;
    Rationale: string | null;
    CompletedByUserName: string | null;
    Name: string | null;
    Status: ImpactRatingStatus;
    PerformanceScore: number | null;
    LikelihoodPerformanceScore: number | null;
    LikelihoodPerformance: string;
    PerformanceRatingValue: ImpactPerformanceRating | null;
  }
>;

export const useGetFieldConfig = ({
  onEdit,
}: {
  onEdit: (testResult: ImpactRatingTableFields) => void;
}): TableFields<ImpactRatingTableFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatings.columns',
  });
  const { getByValue: getRatingByValue } = useRating('impact');
  const { getByValue: getImpactPerformanceByValue } =
    useRating('impact_performance');
  const { getByValue: getImpactRatingStatus } = useRating(
    'impact_rating_status'
  );
  const { getByValue: getImpactPerformanceRating } = useRating(
    'impact_performance_rating'
  );

  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  return useMemo(
    () => ({
      Name: {
        header: st('Name'),
        cell: (item) => (
          <Link variant="secondary" onFollow={() => onEdit(item)} href={`#`}>
            {item.impact.Name}
          </Link>
        ),
        sortingField: 'Name',
        isRowHeader: true,
      },
      Rationale: {
        header: st('Rationale'),
        cell: (item) => item.Rationale,
        sortingField: 'Rationale',
        maxWidth: MAX_COL_WIDTH,
      },
      TestDate: {
        header: st('TestDate'),
        cell: (item) => (item.TestDate ? toLocalDate(item.TestDate) : '-'),
        sortingField: 'TestDate',
      },
      Likelihood: {
        header: st('Likelihood'),
        cell: (item) => (
          <SimpleRatingBadge rating={getLikelihoodByValue(item.Likelihood)} />
        ),
        sortingField: 'Likelihood',
      },
      Status: {
        header: st('Status'),
        cell: (item) => (
          <SimpleRatingBadge rating={getImpactRatingStatus(item.Status)} />
        ),
        sortingField: 'Status',
        maxWidth: MAX_COL_WIDTH,
      },
      Rating: {
        header: st('RatingScore'),
        cell: (item) => {
          const rating = getRatingByValue(item.Rating);
          return (
            <SimpleRatingBadge
              rating={{
                ...rating,
                label: item.Rating.toString(),
                tooltip: rating?.label,
              }}
            />
          );
        },
        sortingField: 'Rating',
      },
      Performance: {
        header: st('PerformanceScore'),
        cell: (item) => {
          const rating = getImpactPerformanceByValue(item.Performance);
          return (
            <SimpleRatingBadge
              rating={{
                ...rating,
                label: item.PerformanceScore?.toString() ?? '',
                tooltip: rating?.label,
              }}
            />
          );
        },
        sortingField: 'PerformanceScore',
      },
      PerformanceRatingValue: {
        header: st('PerformanceRating'),
        cell: (item) => {
          const rating = getImpactPerformanceRating(
            item.PerformanceRatingValue
          );
          return <SimpleRatingBadge rating={rating} />;
        },
        sortingField: 'PerformanceScore',
      },
      LikelihoodPerformance: {
        header: st('LikelihoodPerformance'),
        cell: (item) => {
          const rating = getImpactPerformanceByValue(
            item.LikelihoodPerformance
          );
          return <SimpleRatingBadge rating={rating} />;
        },
        sortingField: 'LikelihoodPerformance',
      },

      CompletedByUserName: {
        header: st('CompletedBy'),
        cell: (item) => item.CompletedByUserName,
        sortingField: 'CompletedByUserName',
      },
    }),
    [
      getImpactPerformanceByValue,
      getImpactPerformanceRating,
      getImpactRatingStatus,
      getLikelihoodByValue,
      getRatingByValue,
      onEdit,
      st,
    ]
  );
};

export const useGetCollectionTableProps = (
  parent: ObjectWithContributors,
  data: GetImpactRatingsByRatedItemIdQuery | undefined,
  appetiteData: GetAppetitesByParentIdQuery | undefined,
  onEdit: (testResult: ImpactRatingTableFields) => void,
  handleRatingsOpen: () => void
): TablePropsWithActions<ImpactRatingTableFields> => {
  const { t } = useTranslation(['common']);
  const labelledFields = useLabelledFields(data, appetiteData);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatings',
  });
  const fields = useGetFieldConfig({ onEdit });
  return useGetTableProps({
    data: labelledFields,
    entityLabel: st('entity_name'),
    enableFiltering: false,
    emptyCollectionAction: (
      <Permission permission="insert:impact_rating" parentObject={parent}>
        <Button formAction="none" onClick={handleRatingsOpen}>
          {t('impactRatingsMultiple.create_new_button')}
        </Button>
      </Permission>
    ),
    storageKey: 'Impact-PreferencesV1',
    initialColumns: [
      'Name',
      'Rationale',
      'TestDate',
      'Likelihood',
      'Status',
      'Rating',
      'Performance',
      'PerformanceRatingValue',
      'LikelihoodPerformance',
      'CompletedByUserName',
    ],
    fields,
  });
};
